import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
// Theme
import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import {  useLocation } from "react-router-dom";
import { Col, Row,  Card } from "react-bootstrap";
import { toast } from "react-toastify";
import  RunReportControls  from './ReportControls.js'
import { LoadingModal } from './AnalyticsCommon.js'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
ModuleRegistry.registerModules([ClientSideRowModelModule]);

function RunReport({ componentProps }) {
    const location = useLocation();
    const [showReportGrid, setShowReportGrid] = useState([]);
    const [showReportControls, setShowReportControls] = useState(false);
    const [ReportControls, setReportControls] = useState([]);
    const [gridData, setGridData] = useState([]);    
    const [reportName, setReportName] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [showLoading, setShowLoading] = useState(true);


    const [reportParams, setReportParams] = useState(new Object())
    const toastProps = useMemo(()=>  {
        const tp = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        }
        return tp;
    },[] )
    const GetReport = useCallback((params) => {
        const reportId = location.pathname.split("/")[2];
        
        setShowModal(true);
        //setShowLoading(true);
        let reqbody = {
            sfId: String(localStorage.getItem("salesforceId")),
            ReportId: String(reportId),
            ProductRole: String(localStorage.getItem('ProductRole')),
            WholeSaleEndCustomerMode: String(localStorage.getItem('WholeSaleEndCustomerMode'))
        }        
        if (params?true:false) {            
                reqbody = Object.assign(reqbody, params)            
        }
       
        
        fetch('/api/AnalyticsController/LoadReports', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: 'post',
            body: JSON.stringify(reqbody)
        }).then((resp) => resp.json()).then((data) => {
            
            if (data.length > 0 && Object.hasOwn(data[0], "RunReportDatas") && data[0]["RunReportDatas"] !== null) {
                setShowModal(false);
                
                if (data[0]["ReportParams"].length > 0) {
                    let params = Object.keys(data[0]["ReportParams"])
                    if (params.length > 0) {
                        let paramdata = [];
                        data[0]["ReportParams"].forEach((v, i, a) => {
                            paramdata.push(a[i])
                        })
                        setReportControls(paramdata);
                        setShowReportControls(true);                        
                    }

                }
                
                if (Object.hasOwn(data[0], "AllowExport")) {
                    let allowexp  = data[0]["AllowExport"];
                    if (data[0]["RunReportDatas"].length > 0) {
                        let allowexport = false;
                        data[0]["RunReportDatas"].forEach((v, i, a) => {                                                       
                            if (!allowexport && a[i]["ReportDataArr"].length > 0) {
                                setShowExport(allowexp);
                                allowexport = true;
                            }
                            
                        })
                        
                    }                   
                }
                    
                
                setGridData(data[0]["RunReportDatas"]);
                setReportName(data[0]["ReportName"]);
                setShowReportGrid(true);
                setShowLoading(false);

            }
            else {
                setShowLoading(false);
                setShowModal(false);
                toast.error("No data Found", toastProps);
            }
                          
        }).catch((error) => {
            setShowModal(false);
            toast.error("Faild to Load report", toastProps);
            console.log(error)
        });

    }, [location, toastProps]);
    useEffect(() => {
        GetReport();
    }, [GetReport])
    const defaultColDefs = { flex: 1 };
    const onGridReady = (params) => {
        //window.addEventListener('resize', () => {
        //    setTimeout(() => {
        //        params.api.sizeColumnsToFit();
        //    }, 0);
        //});
        params.api.sizeColumnsToFit();        
    };
    
   
    const onBtExport = () => {
        
        let repId = location.pathname.split("/")[2];
        let reqbody = {
            sfId: String(localStorage.getItem("salesforceId")),
            ReportId: String(repId),
			ProductRole: String(localStorage.getItem('ProductRole')),
            WholeSaleEndCustomerMode: String(localStorage.getItem('WholeSaleEndCustomerMode'))
        }
        setShowModal(true);
        if (reportParams ? true : false) {
            Object.keys(reportParams).forEach((v, i, a) => {
                if (!Object.hasOwn(reqbody, v))
                    Object.assign(reqbody, { [v]: reportParams[v]})
            })
            let IsExport = "IsExport";
            if (!Object.hasOwn(reqbody, "IsExport"))
                Object.assign(reqbody, { [IsExport]:"true"})          
        }
        fetch("/api/AnalyticsController/Export", {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            cache: "default",
            body: JSON.stringify(reqbody)
        }).then((result) => {

            try {
                if (result.ok === true)
                    return result.blob();
                else {
                    setShowModal(false);
                    throw new Error("Export Failed")
                }
            }
            catch (e) {

                toast.error(e.message, toastProps);
                setShowModal(false);
            }

        }).then((blob) => {

            if (blob ? true : false) {
                var url = URL.createObjectURL(blob);
                let fileName = reportName+".xlsx";
                var link = document.createElement('a');
                link.href = url;
                link.download = fileName;                
                document.body.appendChild(link);                
                link.click();                
                document.body.removeChild(link);
                URL.revokeObjectURL(url);
            }
            setShowModal(false);
        }).catch((error) => {
            setShowModal(false);
            toast.error("Failed to export", toastProps);
            console.log(error)
        });
        
    }
    return (
        <>
            <LoadingModal showModal={showModal}></LoadingModal>
            <Card style={{ backgroundColor:"#edf4f7"} }>
                <Card.Body>
                    <Card.Title>
                        <h3>Run Report :  {reportName ? reportName : ""}</h3> 
                        <hr></hr>
                    </Card.Title>
                    
                    {showReportControls ?
                        <RunReportControls ControlTypes={ReportControls} FnShowReportGrid={setShowReportGrid}
                            FnGetReport={GetReport} FnReportParams={setReportParams} ReportParams={reportParams}></RunReportControls> :
                        <></>
                    }
                    {
                        showExport ? <>
                            <div >
                                <FontAwesomeIcon
                                    onClick={onBtExport}
                                    icon={faFileExcel}
                                    style={{ fontSize: '1.7rem', cursor: 'pointer', marginLeft: '1rem', top: '0.3rem' }}
                                />
                            </div>
                        </> : <></>
                    }
                    {showReportGrid ?
                        gridData.map((v, i, a) => {
                            let colDefs = [];
                            let gridRowdatas = []
                            a[i]["ReportDataArr"].forEach((v, i, aa) => {

                                gridRowdatas.push(aa[i]);
                            })

                            if (gridRowdatas.length > 0) {
                                let keys = Object.keys(a[i]["ReportDataArr"][0]);
                                let gridid = "aggrid_" + i;
                                let showpagination = gridRowdatas.length > 100 ? true : false;
                                let totalrows = gridRowdatas.length;
                                
                                let autoheight = "autoHeight";
                                keys.forEach(key => colDefs.push({ field: key, cellStyle: { marginLeft: '16px' },minWidth:150 }))
                                
                                return (

                                    <>
                                        
                                        <Row style={{ marginTop: "20px" }}>
                                            <Col>
                                                <div><h4>Result Set: {i + 1}</h4></div>
                                                {totalrows < 100 ? <div className="ag-theme-quartz grid gheight" >
                                                    <AgGridReact
                                                        key={i}

                                                        onGridReady={onGridReady}
                                                        defaultColDefs={defaultColDefs}
                                                        rowData={gridRowdatas}
                                                        columnDefs={colDefs}
                                                        pagination={showpagination}
                                                        domLayout={autoheight}
                                                        autoSizeStrategy={{ type: "fitCellContents" }}
                                                        loading={showLoading}
                                                    >
                                                    </AgGridReact>
                                                </div> : <div className="ag-theme-quartz grid " >
                                                    <AgGridReact
                                                            key={i}

                                                            onGridReady={onGridReady}
                                                            defaultColDefs={defaultColDefs}
                                                            rowData={gridRowdatas}
                                                            columnDefs={colDefs}
                                                            pagination={showpagination}
                                                            autoSizeStrategy={{ type: "fitCellContents" }}
                                                            loading={showLoading}
                                                    >
                                                    </AgGridReact>
                                                </div>}
                                                
                                               
                                                

                                            </Col>

                                        </Row>

                                    </>

                                )
                            }
                            else {
                                return (

                                    <>
                                        <Row style={{ marginTop: "20px" }}>
                                            <Col>
                                                <div><h4>Result Set: {i + 1}</h4></div>

                                                <span style={{ color: "red" }}>No records found</span>

                                            </Col>

                                        </Row>

                                    </>

                                )
                            }



                        }) : <></>

                    }  
                </Card.Body>
            </Card>
           
           
           
                     
        </>
    );
}

export default RunReport;