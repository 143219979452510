import React, { useEffect,useRef } from 'react';
import { Spinner } from "reactstrap";
import { AgGridReact } from 'ag-grid-react';
import '@ag-grid-community/styles/ag-grid.min.css';
import '@ag-grid-community/styles/ag-theme-quartz.min.css';
import { Card, CardBody } from "reactstrap";
import '@ag-grid-community/styles/ag-grid.min.css';
import '@ag-grid-community/styles/ag-theme-quartz.min.css'

const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    tooltipComponent: 'customTooltip',
    flex: 1,
};

const ViewMessageLogsTable = ({ data }) => {
    const viewMessageLogsGridRef = useRef('')
   
    const columnDefs = [
        {
            headerName: 'Phone Number',
            headerTooltip: "Phone Number",
            field: 'DeviceId',
            tooltipField: "Phone Number",
            minWidth: 200,
        },
        {
            headerName: 'Date/Time Sent',
            headerTooltip: "Date/Time Sent",
            field: 'createdDateTime',
            tooltipField: "Date/Time Sent",
            minWidth: 125
        },
        {
            headerName: 'Message',
            headerTooltip: 'Message',
            field: 'Message',
            tooltipField: "Message",
            minWidth: 250
        },
        {
            headerName: 'Status',
            headerTooltip: 'Status',
            field: 'Status',
            filter: 'Status',
            tooltipField: "Status",
            minWidth: 125
        }
    ];


    const onGridReady = (params) => {
        window.addEventListener('resize', () => {
            setTimeout(() => {
                params.api.sizeColumnsToFit();
            });
        });
        params.api.sizeColumnsToFit();
    };

    return (
        <>
            <Card>
                <CardBody>
                    <div className="ag-theme-quartz grid" >
                        <AgGridReact
                            ref={viewMessageLogsGridRef}
                            onGridReady={onGridReady}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowData={data}
                            frameworkComponents={{
                                customNoRowsOverlay: () => <div>Please provide valid SIM Number or Phone Number to pull logs.</div>
                            }}
                            noRowsOverlayComponent='customNoRowsOverlay'
                            pagination
                            paginationAutoPageSize
                            enableCellTextSelection
                            loading={false}
                        />
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

export default ViewMessageLogsTable;
