import React, { Component } from 'react';
import Recaptcha from 'react-recaptcha';
//import { Button, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import Button from "react-bootstrap/Button"
import FormLabel from 'react-bootstrap/FormLabel';
import FormGroup from 'react-bootstrap/FormGroup';
import FormControl from 'react-bootstrap/FormControl';
import $ from 'jquery';
import './Login.css';
import advantixLogoVertical from "../../assets/img/illustrations/Grooov_Pulse_logo.png";
import { generateErrorElementWithContactInfo, throwErrorIfNon200Response } from "../../helpers/utils";
import { toast } from "react-toastify";
import { UserContext } from "../context/UserContext"

export class Login extends Component {
    static displayName = Login.name;
    static contextType = UserContext

    constructor(props) {
        document.title = "Advantix SmartSIM Portal - Login"

        window.localStorage.clear();
        $('#loginform').show();
        super(props);
        this.state = {
            username: '',
            password: '',
            isAuthenticated: false,
            resetusername: '',
            registerusername: '',
            loginform: true,
            resetformvalidation: '',
            registerformvalidation: '',
            exceptionmessage: '',
            loading: false,
            captchaverified: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handlePassChange = this.handlePassChange.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handleResetUserChange = this.handleResetUserChange.bind(this);
        this.handleRegisterUserChange = this.handleRegisterUserChange.bind(this);
        this.validateForgotForm = this.validateForgotForm.bind(this);
        this.validateRegisterForm = this.validateRegisterForm.bind(this);
        this.handleForgetPassword = this.handleForgetPassword.bind(this);
        this.handleForgotSubmit = this.handleForgotSubmit.bind(this);
        this.handleRegister = this.handleRegister.bind(this);
        this.handleRegisterSubmit = this.handleRegisterSubmit.bind(this);
        this.captchacallback = this.captchacallback.bind(this);
        this.captchaloaded = this.captchaloaded.bind(this);
        this.handleCancelSubmit = this.handleCancelSubmit.bind(this);
    }
    componentDidMount() {
       // const user = this.context

        console.log(this.context) // { name: 'Tania', loggedIn: true }
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        fetch('/api/AuthenticateUser/UserLogin', {
            headers: { 'Content-Type': 'application/json', },
            method: 'post',
            body: JSON.stringify({ username: this.state.username.toLowerCase(), password: this.state.password })
        }).then(throwErrorIfNon200Response).then((response) => {
            this.setState({ loading: false });
            return response.json().then((data) => {
                if (data.statusType === "Complete") {
                    window.localStorage.setItem('authToken', data.authToken);
                    window.localStorage.setItem('PortalUserAccountId', data.PortalUserAccountId);
                    window.localStorage.setItem('masterAccountId', data.masterAccountId);
                    window.localStorage.setItem('salesforceId', data.salesforceId);
                    window.localStorage.setItem('PortalUsername', data.PortalUsername);
                    window.localStorage.setItem('PortalUserEmail', data.PortalUserEmail);
                    window.localStorage.setItem('PortalUserAccountName', data.PortalUserAccountName);
                    window.localStorage.setItem('PortalUsername', data.PortalUsername);
                    window.localStorage.setItem('PortalUserFullname', data.PortalUserFullname);
                    window.localStorage.setItem('InternalCustomerId', data.InternalCustomerId);
                    window.localStorage.setItem('Address1', data.Address1);
                    window.localStorage.setItem('City', data.City);
                    window.localStorage.setItem('State', data.State);
                    window.localStorage.setItem('Zip', data.Zip);
                    window.localStorage.setItem('AccountName', data.AccountName);
					window.localStorage.setItem('ProductRole', data.ProductRole);
                    window.localStorage.setItem('WholeSaleEndCustomerMode', data.WholeSaleEndCustomerMode);

                    let path = `/home`;
                    this.context.setUser(data)
                    console.log(this.context)

                    this.state.isAuthenticated = true;
                    this.props.history.push(path);
                } else if (data.statusType === "ResetRequired") {
                    localStorage.clear();
                    let path = `/reset-password?${data.resetAuthToken}`;
                    this.props.history.push(path);
                } else if (data.statusType === "Exception") {
                    this.setState({
                        resetformvalidation: 'Exception',
                        exceptionmessage: data.errorMessage,
                    });
                } else {
                    $('.form').addClass('invalid').delay(500).queue(() => {
                        $('.form').removeClass('invalid');
                        $('.form').dequeue();
                    });
                    this.setState({
                        resetformvalidation: 'Error',
                    });
                }
            });
        }).catch((err) => {
            toast.error(generateErrorElementWithContactInfo("Login error. Please confirm correct credentials."));
            this.setState({ loading: false });
            console.log(`error: ${err}`);
        });
    }

    handleForgotSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        fetch('/api/AuthenticateUser/ForgotPassword', {
            headers: { 'Content-Type': 'application/json', },
            method: 'post',
            body: JSON.stringify({ username: this.state.resetusername })
        }).then(throwErrorIfNon200Response).then((response) => {
            return response.json().then((data) => {
                if (data.statusType === "Complete") {
                    this.setState({
                        resetformvalidation: 'Complete'
                    })
                } else if (data.statusType === "Exception") {
                    this.setState({
                        resetformvalidation: 'Exception',
                        exceptionmessage: data.errorMessage,
                    });
                } else {
                    this.setState({
                        resetformvalidation: 'Error'
                    })
                }
            });
        }).catch((err) => {
            toast.error(generateErrorElementWithContactInfo("Unable to reset password. Please reach out to Advantix Support for assistance."));
            this.setState({ loading: false });
            console.log(`error: ${err}`);
        });
    }

    handleRegisterSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        fetch('/api/AuthenticateUser/RegisterUser', {
            headers: { 'Content-Type': 'application/json', },
            method: 'post',
            body: JSON.stringify({ username: this.state.registerusername })
        }).then(throwErrorIfNon200Response).then((response) => {
            return response.json().then((data) => {
                if (data.statusType === "Complete") {
                    this.setState({
                        registerformvalidation: 'Complete'
                    })
                } else if (data.statusType === "Exception") {
                    this.setState({
                        registerformvalidation: 'Exception',
                        exceptionmessage: data.errorMessage,
                    });
                } else {
                    this.setState({
                        registerformvalidation: 'Error'
                    })
                }
            });
        }).catch((err) => {
            toast.error(generateErrorElementWithContactInfo("Invalid password. Please try again."));
            this.setState({ loading: false });
            console.log(`error: ${err}`);
        });
    }


    handleUserChange(evt) {
        this.setState({
            username: evt.target.value,
        });
    }

    handlePassChange(evt) {
        this.setState({
            password: evt.target.value,
        });
    }

    handleResetUserChange(evt) {
        this.setState({
            resetusername: evt.target.value,
        });
    }

    handleRegisterUserChange(evt) {
        this.setState({
            registerusername: evt.target.value,
        });
    }

    validateForm() {
        return this.state.username.length > 5 && this.state.password.length > 5;
    }

    validateForgotForm() {
        return this.state.resetusername.length > 5 //&& this.state.captchaverified; // add condition for capta;
    }
    validateRegisterForm() {
        return this.state.registerusername.length > 5 && this.state.captchaverified; // add condition for capta;
    }

    handleForgetPassword() {
        $('.form').addClass('flip').delay(10).queue(() => {
            $('.form').removeClass('flip');
            $('.form').dequeue();
        });
        this.setState({
            loginform: false,
            registerform: false,
            forgotform: true,
            resetusername: '',
            resetformvalidation: '',
        })
    }
    handleRegister() {
        $('.form').addClass('flip').delay(10).queue(() => {
            $('.form').removeClass('flip');
            $('.form').dequeue();
        });
        this.setState({
            loginform: false,
            forgotform: false,
            registerform: true,
            registerusername: '',
            registerformvalidation: '',
        })
    }

    handleCancelSubmit() {
        $('.form').addClass('flip').delay(10).queue(() => {
            $('.form').removeClass('flip');
            $('.form').dequeue();
        });
        this.setState({
            loginform: true,
            registerform: false,
            forgotform: false,
            resetusername: '',
            registerusername: '',
            resetformvalidation: '',
            registerformvalidation: '',
            exceptionmessage: '',
            loading: false
        })
    }
    captchacallback(evt) {
        if (evt) {
            this.setState({ captchaverified: true })
        }

    }
    captchaloaded() {
        console.log('capta loaded');
    }

    render() {
        const { loading } = this.state;

        return (
            <>
                <div className="Login">
                    <form id="loginform" className="form" onSubmit={this.handleSubmit}
                        style={{ display: this.state.loginform ? 'block' : 'none' }}>
                        <div className="d-flex justify-content-center mb-4">
                            <img className="logo-vertical" src={advantixLogoVertical} alt="Advantix Logo" />
                        </div>
                        <FormGroup controlId="username">
                            <FormLabel>Email</FormLabel>
                            <FormControl autoComplete="username" autoFocus type="username" value={this.state.username}
                                onChange={this.handleUserChange} />
                        </FormGroup>
                        <FormGroup controlId="password">
                            <FormLabel>Password</FormLabel>
                            <FormControl autoComplete="current-password" value={this.state.password} type="password"
                                onChange={this.handlePassChange} />
                        </FormGroup>
                        <p align="center" style={{
                            color: 'red',
                            display: this.state.resetformvalidation === 'Exception' ? 'block' : 'none'
                        }}>{generateErrorElementWithContactInfo(this.state.exceptionmessage)}</p>
                        <p align="center" style={{
                            color: 'red',
                            display: this.state.resetformvalidation === 'Error' ? 'block' : 'none'
                        }}>Invalid username or password.</p>
                        {loading ?
                            <>
                                <p className="mt-1 loading-text">Authorizing</p>
                                <div className="d-flex mb-3">
                                    <div className="m-auto dot-flashing"></div>
                                </div>
                            </>
                            :
                            <>
                                <Button block disabled={!this.validateForm()} style={{width:'100%'} }
                                    color={this.validateForm() === true ? "#20AC07" : "#FFFFFF"} type="submit">
                                    Login
                            </Button>
                                <br></br>
                                <p align="center"><a text-align="center" type="click"
                                    href="#"
                                    onClick={this.handleForgetPassword}>Forgot Password ?</a></p>
                                <p style={{ display: 'none' }} align="center"><a text-align="center" type="click"
                                    href="#"
                                    onClick={this.handleRegister}>Sign Up</a></p>
                            </>
                        }
                    </form>
                    <form id="forgotform" className="form" onSubmit={this.handleForgotSubmit}
                        style={{ display: !this.state.loginform && this.state.forgotform ? 'block' : 'none' }}>
                        <p align="center" style={{
                            color: 'green',
                            display: this.state.resetformvalidation === 'Complete' ? 'block' : 'none'
                        }}>Email sent to your Id. Please Check your email to verify your identity.</p>
                        <FormGroup controlId="username"
                            style={{ display: this.state.resetformvalidation !== 'Complete' ? 'block' : 'none' }}>
                            <FormLabel>Email</FormLabel>
                            <FormControl autoFocus type="username" value={this.state.resetusername}
                                onChange={this.handleResetUserChange} />
                        </FormGroup>
                        <p align="center" style={{
                            color: 'red',
                            display: this.state.resetformvalidation === 'Error' ? 'block' : 'none'
                        }}>Email you have entered is Invalid. Please enter valid email Address</p>
                        <p align="center" style={{
                            color: 'red',
                            display: this.state.resetformvalidation === 'Exception' ? 'block' : 'none'
                        }}>
                            {generateErrorElementWithContactInfo(this.state.exceptionmessage)}
                        </p>
                        <Button block disabled={!this.validateForgotForm()} onSubmit={this.handleForgotSubmit} type="submit" color={this.validateForgotForm() === true ? "#20AC07" : "#FFFFFF"} style={{ display: this.state.resetformvalidation !== 'Complete' ? 'block' : 'none' }}>
                            Send Resend Link
                    </Button>
                        <br></br>
                        <p align="center"><a href="#" text-align="center" type="click"
                            onClick={this.handleCancelSubmit}>Cancel</a></p>
                    </form>
                    <form id="registerform" className="form" onSubmit={this.handleRegisterSubmit}
                        style={{ display: !this.state.loginform && this.state.registerform ? 'block' : 'none' }}>
                        <p align="center" style={{
                            color: 'green',
                            display: this.state.registerformvalidation === 'Complete' ? 'block' : 'none'
                        }}>Email sent to your Id. Please Check your email to verify your identity.</p>
                        <FormGroup controlId="username"
                            style={{ display: this.state.registerformvalidation !== 'Complete' ? 'block' : 'none' }}>
                            <FormLabel>Email</FormLabel>
                            <FormControl autoFocus type="email" value={this.state.registerusername}
                                onChange={this.handleRegisterUserChange} />
                        </FormGroup>
                        <p align="center" style={{
                            color: 'red',
                            display: this.state.registerformvalidation === 'Error' ? 'block' : 'none'
                        }}>Email you have entered doesn't exist. Please enter valid email Address</p>
                        <p align="center" style={{
                            color: 'red',
                            display: this.state.registerformvalidation === 'Exception' ? 'block' : 'none'
                        }}>
                            {generateErrorElementWithContactInfo(this.state.exceptionmessage)}
                        </p>
                        <Recaptcha
                            sitekey="6LcLmhIcAAAAAATylxbxZq7pTnotDtMDBv_2V-kY"
                            render="explicit"
                            onloadCallback={this.captchaloaded}
                            verifyCallback={this.captchacallback}
                            elementid="cap1"
                            style={{
                                display: this.state.registerformvalidation !== 'Complete' ? 'block' : 'none',
                                disabled: this.state.registerformvalidation !== 'Complete' ? 'block' : 'none'
                            }}
                        />
                        <Button block disabled={!this.validateRegisterForm()} onSubmit={this.handleRegisterSubmit} type="submit" color={this.validateRegisterForm() === true ? "#20AC07" : "#FFFFFF"} style={{ display: this.state.registerformvalidation !== 'Complete' ? 'block' : 'none' }}>
                            Send Registration Link
                    </Button>
                        <br></br>
                        <p align="center"><a href="#" text-align="center" type="click"
                            onClick={this.handleCancelSubmit}>Cancel</a></p>
                    </form>
                </div>
            </>
        );
    }
}