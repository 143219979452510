import React, { useState, useRef } from 'react'
import Select from 'react-select';
import { Col, Row, FormGroup, FormControl, Button,FormCheck } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Reports.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCalendarAlt} from "@fortawesome/free-solid-svg-icons";
function RunReportControls({ ControlTypes, FnShowReportGrid, FnGetReport, FnReportParams, ReportParams }) {
    const textControlTypes = ["string", "number", "date", "intlist", "phonelist"];
    const dropdownControlType = "lookup";
    const checkControlType = "bit";
    const [startDate, setStartDate] = useState(new Date());
    
    const [checkchange, setCheckChange] = useState(false);
    const [textVal, setTextVal] = useState("");
  
    let defaultParamVals= new Object();
    
    
    const handleSelect = (ele,pname) => {
       
        let repparam = ReportParams
         
        if (Object.hasOwn(repparam, pname)) {
            repparam[pname] = ele.value
        }
        else {
            Object.assign(repparam, { [pname]: ele.value })
        }
        
        FnReportParams(repparam);
        
    }
    const handleDateChange = (ele,pname) => {
        
        let seldate = ele.toLocaleDateString();
        let repparam = ReportParams        
        if (Object.hasOwn(repparam, pname)) {
            repparam[pname] = seldate
        }
        else {
            Object.assign(repparam, { [pname]: seldate })
        }
        
        FnReportParams(repparam);
        let chosendate =  new Date(repparam[pname])
        setStartDate(chosendate)        
    }
    const handleInputChange = (ele,pId) => {
        
        let repparam = ReportParams        
        if (Object.hasOwn(repparam, pId)) {
            repparam[pId] = ele.value
        }
        else {
            Object.assign(repparam, { [pId]: ele.value })
        }
              
        FnReportParams(repparam);
        setTextVal(repparam[pId]);
    }
    const handleRunReport = () => {
        
        let repparam = ReportParams
        Object.keys(defaultParamVals).forEach((v, i, a) => {
            if (!Object.hasOwn(repparam, v)) {               
                Object.assign(repparam, { [v]: defaultParamVals[v] })
            }
        })       
        FnReportParams(repparam);
        FnGetReport(ReportParams);                
    }
    const handleCheckChange = (e,pname) => {
        let repparam = ReportParams       
        if (Object.hasOwn(repparam, pname)) {
            repparam[pname] = String(e.target.checked)
        }
        else {
            Object.assign(repparam, { [pname]: String(e.target.checked) })
        }
        let checkBoxCtl = document.getElementById(pname);
        checkBoxCtl.setAttribute('checked', Boolean(repparam[pname]))
        
        setCheckChange(e.target.checked);
    }
   
    return (
        <>
            
         
                {ControlTypes ? ControlTypes.map((v, i, a) => {
                   
                    if (textControlTypes.indexOf(v["ParamType"]) !== -1) {
                        let pType = textControlTypes[textControlTypes.indexOf(v["ParamType"])]
                        let pId = "param-" + i + "-" + v["ParamName"] + "-" + pType;
                        let desc = v["Description"];

                        let repparam = defaultParamVals
                        if (!Object.hasOwn(repparam, pId)) {
                            if (pType === "date") {
                                let d = new Date();
                                Object.assign(repparam, { [pId]: d.toLocaleDateString() })
                            }
                            else
                                Object.assign(repparam, { [pId]: "" })
                        }
                        defaultParamVals = repparam;
                        
                        return (
                            <Row style={{ marginTop: "10px" }}>
                                <Col md={4}>
                                    <span className="LabelsHeadder">{desc}:</span>  
                                </Col>
                                <Col>
                                    {pType === "date" ? <>

                                        <div style={{ display: "flex", gap: "2px" }}>
                                            <div className="reactdatepickerwrapp" >
                                                <DatePicker
                                                    showIcon={true}


                                                    key={pId} id={pId}
                                                    selected={
                                                        ReportParams ?
                                                            ReportParams[pId] ?
                                                                new Date(ReportParams[pId])
                                                                : new Date()
                                                            : new Date()
                                                    }
                                                    onChange={(ele) => handleDateChange(ele, pId)} >
                                                </DatePicker>
                                            </div>
                                            
                                            <div><FontAwesomeIcon

                                                icon={faCalendarAlt}
                                                style={{ fontSize: '1.7em', cursor: 'pointer', marginLeft: '0.15em', top: '0.3em' }}
                                            /></div>
                                            
                                        </div>
                                                
                                                                                   
                                        
                                       </> :
                                        <FormGroup>
                                            <FormControl
                                                key={pId}
                                                id={pId}
                                                tabIndex="1"
                                                className="input-manage-field"
                                                type="text"
                                                value={Object.hasOwn(ReportParams, pId) ? ReportParams[pId] : ""}
                                                onChange={(e) => handleInputChange(e.target, pId)}
                                            />
                                        </FormGroup>}


                                </Col>
                            </Row>

                        )
                    }
                    else if (dropdownControlType === v["ParamType"]) {
                        let selectOptions = []
                        let pname = v["ParamName"];
                        let desc = v["Description"];
                        let ctrlId = "param-" + i + "-" + pname + "-" + dropdownControlType;

                        try {
                            let parval = JSON.parse(v["ParamValue"]);
                            
                            parval.forEach((v, i, a) => {
                                let op = new Option(a[i]["Text"], a[i]["Id"], false, i === 0 ? true : false);                                
                                selectOptions.push(op)
                            })
                        }
                        catch {
                            selectOptions.push(new Option('', '-1', false, true))                            
                        }
                        let repparam = defaultParamVals
                        if (!Object.hasOwn(repparam, ctrlId)) {
                            Object.assign(repparam, { [ctrlId]: selectOptions[0].value })
                        }
                        defaultParamVals = repparam;                       
                        return (
                            <Row style={{ marginTop: "10px" }}>
                                <Col md={4}>
                                    <span className="LabelsHeadder">{desc}:</span> 
                                </Col>
                                <Col>
                                    <div >
                                        <Select
                                            key={i}
                                            id={ctrlId}
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isSearchable="true"
                                            name="UserDescription"
                                            onChange={(e) => handleSelect(e, ctrlId)}
                                            options={selectOptions}
                                            defaultValue={selectOptions[0]}
                                        />
                                    </div>
                                </Col>
                            </Row>

                        )
                    }
                    else if (checkControlType === v["ParamType"]) {
                        let parId = "param-" + i + "-" + v["ParamName"] + "-" + checkControlType;
                        let desc = v["Description"]
                        let repparam = defaultParamVals
                        if (!Object.hasOwn(repparam, parId)) {
                            Object.assign(repparam, { [parId]: 'false' })
                        }
                        defaultParamVals = repparam;
                        
                        return (<>

                            <Row style={{ marginTop: "10px" }}>
                                <Col md={4}>
                                    <span className="LabelsHeadder">{desc}:</span> 
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <FormCheck
                                            ref={new useRef()}
                                            key={parId}
                                            id={parId}
                                            type="checkbox"

                                            onChange={(e) => handleCheckChange(e, parId)}


                                        >

                                        </FormCheck>
                                    </FormGroup>
                                </Col>

                            </Row>


                        </>)
                    }
                    else {
                        return <></>
                    }

                }) : <></>
                }
         

            < Row style={{ marginTop: "10px" }}>
                <Col>
                    <div style={{ display: "flex", justifyContent:"end" }}>
                        <FormGroup lg={10} md={8}>
                            <Button as="input" type="button" value="Run Report" onClick={handleRunReport} />
                        </FormGroup>
                    </div>
                  
                </Col>
            </Row >
        </>
        
        
    )

}

export default RunReportControls


