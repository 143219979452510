import ShippingAddress from "./ShippingAddress"
import ContactInformation from "./ContactInformationOrder"
import { FormControl, FormGroup, Col, Row, Button, Dropdown, Form } from 'react-bootstrap';
import { themeColors } from "../../helpers/utils";
import { useState, useEffect } from "react"
import NewSimRatePlan from "./NewSimRatePlan"
import { toast } from 'react-toastify';
import { counter } from "@fortawesome/fontawesome-svg-core";
import Select from 'react-select';

const OrderSimandRatePlan = ({ removeRatePlanFromMap, isCurrentEmpty, simDuplicationError, currentPlan, setCurrentRatePlansCounter, usageData, setSimQuality, SelectedRatePlansMap, setRatePlanMap, updatedRatePlans, removeSelectedRatePlan, SIMQuantity, error, setQuanity, OrderId, updateOrderId, setSelectedRate, MAXRatePlan, RatePlanCounter, selectedRate, SelectedRatePlans, ratePlanCounter, RatePlans, addPlan, removePlan, setContontactInformation, setShippInformation, onProductChange }) => {
    const [newRateList, setNewRateList] = useState([])
    const [updatedRatePlan, setUpdateRaePlan] = useState([])
    const [newratePlanCounter, setRatePlanCounter] = useState(0)
    const [currentSelectedPlan, setCurrentSelectedPlan] = useState(null)
    // const [currentSelectedRatePlans, setCurrentSelectedRatePlans] = useState([])
    const [currentRatePlan, setCurrentRatePlan] = useState(null)
    const [isCurrentRatePlanEmpty, setIsCurrentRatePlanEmpty] = useState(false)
    const [products, setProducts] = useState({});
    const [filteredRatePlans, setFilteredRatePlans] = useState(RatePlans);
    let selectedProduct = '';
    useEffect(() => {
        fetchProductInfo();
    }, []);

    useEffect(() => {
        setIsCurrentRatePlanEmpty(isCurrentEmpty)
    }, [isCurrentEmpty])

    const setSIMQuanity = (data, id) => {
        setSimQuality(data, id.toString())
    }

    const setSelectedRatePlans = (sku, data, id) => {
        setRatePlanMap(sku, data, id)
    }


    const setRatePlanValue = (data, sku) => {
        setCurrentSelectedPlan(data)
        setSelectedRate(data)

    }

    const fetchProductInfo = () => {
        fetch("api/OrderController/GetRatePlansProduct", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: "POST",
            body: JSON.stringify({
                sfAccountId: localStorage.getItem('salesforceId')
            })
        })
            .then(response => response.json())
            .then(data => {
                console.log('Fetched data:', data);
                setProducts(data);
            })
            .catch(error => {
                console.error('Error fetching Product info:', error);
            });
    };

    useEffect(() => {
        let temp = []
        let initValue = []

        temp.push(<NewSimRatePlan
            MAXRatePlan={RatePlans?.length > MAXRatePlan ? MAXRatePlan : RatePlans?.length}
            RatePlans={RatePlans}
            isCurrentRatePlanEmpty={isCurrentRatePlanEmpty}
            updatedRatePlans={updatedRatePlans}
            error={error}
            setSelectedRatePlans={setSelectedRatePlans}
            currentPlan={currentPlan}
            SelectedRatePlansMap={SelectedRatePlansMap}
            removeSelectedRatePlan={removeSelectedRatePlan}
            TestSim={setSIMQuanity}
            SIMQuantity={SIMQuantity}

            id={newratePlanCounter}
            setQuanity={setQuanity}
            setRatePlanValue={setRatePlanValue}
            currentSelectedRatePlans={[]}
            defaultValue={RatePlans?.[0]?.description} />)
        initValue.push([RatePlans?.[0]])
        setNewRateList(temp)
        if (RatePlans) {
            initValue.push(RatePlans[0])
            setUpdateRaePlan(initValue)
        }


    }, [RatePlans, isCurrentRatePlanEmpty])

    const addRatePlan = () => {
        let tempRatePlans = []
        if (SelectedRatePlansMap === null || (SelectedRatePlansMap.get(newratePlanCounter.toString()) === undefined)) {
            toast.error("Please select a Rate Plan and Quantity before submitting.")
            return;
        }


        for (let [key, value] of SelectedRatePlansMap) {
            if (value['SIMQuantity'].length <= 0) {
                toast.error("Please enter a quantity " + value['SIMQuantity'])
                return;
            }

        }
        tempRatePlans.push(currentSelectedPlan)
        let temp = []
        let selectedRatePlanLists = []
        if (newratePlanCounter < MAXRatePlan - 1) {
            setRatePlanCounter((counter) => {
                let newCounter = counter + 1;
                setCurrentRatePlansCounter(newCounter)
                return newCounter
            })
            temp.push(
                <NewSimRatePlan
                    id={newratePlanCounter + 1}
                    RatePlans={RatePlans}
                    isCurrentRatePlanEmpty={isCurrentRatePlanEmpty}
                    SelectedRatePlansMap={SelectedRatePlansMap}
                    TestSim={setSIMQuanity}
                    setSelectedRatePlans={setSelectedRatePlans}
                    MAXRatePlan={RatePlans?.length > MAXRatePlan ? MAXRatePlan : RatePlans?.length}
                    removeSelectedRatePlan={removeSelectedRatePlan}
                    error={error}
                    updatedRatePlans={updatedRatePlans}
                    setRatePlanValue={setRatePlanValue}
                    setQuanity={setQuanity}
                    SIMQuantity={SIMQuantity}
                    defaultValue={RatePlans?.[newratePlanCounter + 1].description} />)

            selectedRatePlanLists.push(RatePlans[newratePlanCounter])
            setUpdateRaePlan([...selectedRatePlanLists, ...updatedRatePlan])
            setNewRateList([...newRateList, ...temp])
        }
    }

    const removeRatePlan = () => {
        let temp = newRateList

        if (newratePlanCounter > 0) {
            setRatePlanCounter((counter) => {
                localStorage.setItem('idkey', counter)
                removeRatePlanFromMap(counter)
                const updateCounter = counter - 1;
                return updateCounter
            })
            temp.pop()
            setNewRateList([...temp])
        }
    }
    const setContactInformations = (data) => {
        setContontactInformation(data)
    }

    const handleRatePlanProductSelect = (option) => {
        onProductChange(option.value)
        setNewRateList([]);
        setRatePlanCounter(0);
    };

   const productOptions = Object.keys(products).map(key => ({
        value: key,
        label: products[key]
   }));

    return (
        <>
            <Row className="mt-3 " style={{ alignItems: "center" }} >
                <Col sm={12} md={9} lg={4} xl={2} className="field-root"    >
                    <span style={{ fontSize: "0.9rem" }} className="live-data-title mr-4">Account Name</span>
                </Col>
                <Col sm={12} md={9} lg={4} xl={3} className=" manage-field-root" >
                    <Select
                        className="basic-single"
                        isSearchable={false}
                        isDisabled={true}
                        name="Account"
                        value={{ label: usageData?.['CustomerName'], value: usageData?.['CustomerName'] }}
                        options={[{ label: usageData?.['CustomerName'], value: usageData?.['CustomerName'] }]}
                    />
                </Col>
            </Row>
            <Row className="mt-3 " style={{ alignItems: "center", autoComplete: "On" }} >
                <Col sm={12} md={9} lg={4} xl={2} className="field-root"    >
                    <span style={{ fontSize: "0.9rem" }} className="live-data-title mr-4">Order ID</span>
                </Col>
                <Col sm={12} md={9} lg={4} xl={3} className=" manage-field-root" >
                    <FormGroup controlId="OrderID" >
                        <FormControl style={{ border: error && OrderId.length <= 0 ? '1px solid red' : '1px solid rgb(135, 163, 195)' }}
                            onChange={(event) => { updateOrderId(event.target.value) }}
                            autoComplete="Order ID"
                            className="input-manage-field"
                            autoFocus
                            tabIndex= "1"
                            type="text" />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mt-3 " style={{ alignItems: "center" }} >
                <Col sm={12} md={9} lg={4} xl={2} className="field-root"    >
                    <span style={{ fontSize: "0.9rem" }} className="live-data-title mr-4">Product</span>
                </Col>
                <Col sm={12} md={9} lg={4} xl={6} className="manage-field-root" >
                    <FormGroup >
                        <Select
                            className="basic-single"
                            isSearchable={false}
                            isDisabled={false}
                            onChange={(option) => handleRatePlanProductSelect(option)}
                            name="Product"
                            options={productOptions}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <>
                {newRateList.map((selected, index) => {
                    return (
                        <> {selected} </>
                    )
                })}
            </>
            <Row className="mt-4 mb-4">
                {RatePlans.length > newratePlanCounter + 1 ?
                    <Col lg={6}>
                        <Button onClick={
                            () => {
                                addRatePlan()
                            }
                        } style={{ background: "transparent", border: "none", color: themeColors.primary }}><span style={{ textDecoration: "underline", whiteSpace: "nowrap" }}>+ additional Rate Plan</span></Button>
                    </Col> : null
                }
                {RatePlans.length > 0 && newratePlanCounter > 0 ?
                    <Col lg={6}>
                        <Button onClick={() => {
                            removeRatePlan()
                        }} style={{ background: "transparent", border: "none", color: themeColors.primary }}><span style={{ textDecoration: "underline" }}>- remove additional Rate Plan</span></Button>
                    </Col> : null
                }
            </Row>
            <ContactInformation usageData={usageData} error={error} setContactInformations={(data) => { setContactInformations(data) }} />
        </>
    )


}

export default OrderSimandRatePlan

       