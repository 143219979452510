import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col, FormGroup, FormControl, FormCheck } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import { throwErrorIfNon200Response, themeColors, generateErrorElementWithContactInfo } from "../../helpers/utils";
import { toast } from "react-toastify";
import { LoadingModal } from '../ChangeServices/ChangeServiceCommon.js'
function AccountBillingInformation() {
    let paymentUrl = '';
    const [isEditing, setIsEditing] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        sfAccountId: localStorage.getItem('salesforceId'),
        contactFirstName: '',
        contactLastName: '',
        businessPhone: '',
        mobilePhone: '',
        emailAddress: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        postalCode: '',
        paymentMethod: ''
    });
    const [isXlScreen, setIsXlScreen] = useState(window.innerWidth >= 1200);
    const regexCheck = /^\d{10}$/;
    const formatPhoneNumber = (input) => {
        return input.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    };

    useEffect(() => {
        const handleResize = () => {
            setIsXlScreen(window.innerWidth >= 1200);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        fetchBillingInfo();
       
        if (process.env.REACT_APP_UPDATE_PAYMENT_URL) {
            let pmtUrl = new URL(process.env.REACT_APP_UPDATE_PAYMENT_URL);
            pmtUrl.searchParams.set('Email', window.localStorage.getItem("PortalUserEmail"));
            paymentUrl = pmtUrl.href;
        }
    }, []);

    const fetchBillingInfo = () => {
        setShowModal(true);
        fetch("api/AccountManagementController/GetBillingInfoBasedOnAccount", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: "POST",
            body: JSON.stringify({
                sfAccountId: localStorage.getItem('salesforceId')
            })
        })
            .then(response => response.json())
            .then(data => {
                console.log('Fetched data:', data);
                if (data) {
                    setFormData(data);
                }
                setShowModal(false);
            })
            .catch(error => {
                setShowModal(false);
                console.error('Error fetching billing info:', error);
            });
    };

    const updateBillingInfo = () => {
        setShowModal(true);
        fetch("api/AccountManagementController/UpdateBillingInfoBasedOnAccount", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: "POST",
            body: JSON.stringify(formData)
        })
            .then(response => response.json())
            .then(data => {
                if (data.Status === "Completed") {
                    toast.success(data.StatusMessage);
                } else {
                    toast.error(data.StatusMessage);                    
                }
                setShowModal(false);
            })
            .catch(error => {
                setShowModal(false);
                console.error('Error saving data:', error);
            })
            .finally(() => {
                fetchBillingInfo();
            });
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveChanges = () => {
        updateBillingInfo()
        setIsEditing(false);
    };

    const handleCancelClick = () => {
        fetchBillingInfo()
        setIsEditing(false);
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        let updatedValue = value;
        if (id === "businessPhone") {
            const sanitizedInput = value.replace(/[^0-9-]/g, '');
            updatedValue = sanitizedInput;
            if (regexCheck.test(sanitizedInput)) {
                updatedValue = formatPhoneNumber(sanitizedInput);
            }
        }

        if (id === "mobilePhone") {
            const sanitizedInput = value.replace(/[^0-9-]/g, '');
            updatedValue = sanitizedInput;
            if (regexCheck.test(sanitizedInput)) {
                updatedValue = formatPhoneNumber(value);
            }
        }

        setFormData({ ...formData, [id]: updatedValue, sfAccountId: localStorage.getItem('salesforceId') });
    };

    const handlePaymentMethodChange = (e) => {
        const { value } = e.target;

        setFormData(prevFormData => ({
            ...prevFormData,
            paymentMethod: value,
            sfAccountId: localStorage.getItem('salesforceId') || prevFormData.sfAccountId
        }));
    };


    const customColStyle = isXlScreen
        ? { flex: '0 0 16.66667%', maxWidth: '17.66667%' }
        : {};

    return (
        <>
            <LoadingModal showModal={showModal} >
            </LoadingModal>
            <Row>
                <h5 class="mb-4">Billing Contact Information</h5>

                <Row className="ml-1">
                    <Col className="" md={4} lg={4} xl={2} >
                        <span style={{ fontSize: "0.9rem" }} className="live-data-title">Contact First Name</span>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={3} className="mb-3" >
                        {isEditing ? (
                            <FormGroup controlId="contactFirstName">
                                <FormControl
                                    maxLength={50} tabIndex="1"
                                    className="input-manage-field"
                                    type="text"
                                    value={formData.contactFirstName}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        ) : (
                            <span style={{ fontSize: "0.9rem" }} className='text-secondary mb-2'>{formData.contactFirstName || 'N/A'}</span>
                        )}
                    </Col>
                    <Col className="" md={4} lg={4} xl={2} style={customColStyle}>
                        <span style={{ fontSize: "0.9rem" }} className="live-data-title">Business Phone Number</span>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={3} className="mb-3"  >
                        {isEditing ? (
                            <FormGroup controlId="businessPhone">
                                <FormControl
                                    maxLength={20} tabIndex="4"
                                    className="input-manage-field"
                                    type="text"
                                    value={formData.businessPhone}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        ) : (
                            <span style={{ fontSize: "0.9rem" }} className='text-secondary mb-2'>{formData.businessPhone || 'N/A'}</span>
                        )}
                    </Col>
                </Row>
                <Row className="ml-1">
                    <Col className="" md={4} lg={4} xl={2} >
                        <span style={{ fontSize: "0.9rem" }} className="live-data-title">Contact Last Name</span>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={3} className="mb-3" >
                        {isEditing ? (
                            <FormGroup controlId="contactLastName" >
                                <FormControl
                                    maxLength={50} tabIndex="2"
                                    className=" input-manage-field" type="text"
                                    value={formData.contactLastName}
                                    onChange={handleInputChange}
                                />
                            </FormGroup>
                        ) : (
                            <span style={{ fontSize: "0.9rem" }} className='text-secondary mb-2'>{formData.contactLastName || 'N/A'}</span>
                        )}
                    </Col>
                    <Col className="" md={4} lg={4} xl={2} style={customColStyle} >
                        <span style={{ fontSize: "0.9rem" }} className="live-data-title">Mobile Phone Number</span>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={3} className="mb-3"  >
                        {isEditing ? (
                            <FormGroup controlId="mobilePhone" >
                                <FormControl
                                    maxLength={20} tabIndex="5"
                                    value={formData.mobilePhone}
                                    onChange={handleInputChange}
                                    className=" input-manage-field" type="text" />
                            </FormGroup>
                        ) : (
                            <span style={{ fontSize: "0.9rem" }} className='text-secondary mb-2'>{formData.mobilePhone || 'N/A'}</span>
                        )}
                    </Col>
                </Row>
                <Row className="ml-1" >
                    <Col className="" md={4} lg={4} xl={2}  >
                        <span style={{ fontSize: "0.9rem" }} className="live-data-title">Email Address</span>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={3} className="mb-3"  >
                        {isEditing ? (
                            <FormGroup controlId="emailAddress" >
                                <FormControl
                                    maxLength={100} tabIndex="3"
                                    className=" input-manage-field" type="email" value={formData.emailAddress}
                                    onChange={handleInputChange} />
                            </FormGroup>
                        ) : (
                            <span style={{ fontSize: "0.9rem" }} className='text-secondary mb-2'>{formData.emailAddress || 'N/A'}</span>
                        )}
                    </Col>
                </Row>
                <h5 class="mb-4 mt-3">Billing Address Information</h5>
                <Row className="ml-1">
                    <Col className="" md={4} lg={4} xl={2}  >
                        <span style={{ fontSize: "0.9rem" }} className="live-data-title">Address 1</span>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={3} className="mb-3"  >
                        {isEditing ? (
                            <FormGroup controlId="address1" >
                                <FormControl
                                    maxLength={50} tabIndex="6"
                                    className=" input-manage-field" value={formData.address1}
                                    onChange={handleInputChange} />
                            </FormGroup>
                        ) : (
                            <span style={{ fontSize: "0.9rem" }} className='text-secondary mb-2'>{formData.address1 || 'N/A'}</span>
                        )}
                    </Col>
                </Row>
                <Row className="ml-1">
                    <Col className="" md={4} lg={4} xl={2}  >
                        <span style={{ fontSize: "0.9rem" }} className="live-data-title">Address 2</span>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={3} className="mb-3"  >
                        {isEditing ? (
                            <FormGroup controlId="address2" >
                                <FormControl
                                    maxLength={50} tabIndex="7"
                                    value={formData.address2}
                                    onChange={handleInputChange}
                                    className=" input-manage-field" />
                            </FormGroup>
                        ) : (
                            <span style={{ fontSize: "0.9rem" }} className='text-secondary mb-2'>{formData.address2 || 'N/A'}</span>
                        )}
                    </Col>
                </Row>
                <Row className="ml-1">
                    <Col className="" md={4} lg={4} xl={2}  >
                        <span style={{ fontSize: "0.9rem" }} className="live-data-title">City</span>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={3} className="mb-3"  >
                        {isEditing ? (
                            <FormGroup controlId="city" >
                                <FormControl
                                    maxLength={30} tabIndex="8"
                                    value={formData.city}
                                    onChange={handleInputChange}
                                    className=" input-manage-field" />
                            </FormGroup>
                        ) : (
                            <span style={{ fontSize: "0.9rem" }} className='text-secondary mb-2'>{formData.city || 'N/A'}</span>
                        )}
                    </Col>
                </Row>
                <Row className="ml-1">
                    <Col className="" md={4} lg={4} xl={2}  >
                        <span style={{ fontSize: "0.9rem" }} className="live-data-title">State</span>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={3} className="mb-3"  >
                        {isEditing ? (
                            <FormGroup controlId="state" >
                                <FormControl
                                    maxLength={15} tabIndex="9"
                                    value={formData.state}
                                    onChange={handleInputChange} className=" input-manage-field" />
                            </FormGroup>
                        ) : (
                            <span style={{ fontSize: "0.9rem" }} className='text-secondary mb-2'>{formData.state || 'N/A'}</span>
                        )}
                    </Col>
                </Row>
                <Row className="ml-1">
                    <Col className="" md={4} lg={4} xl={2}  >
                        <span style={{ fontSize: "0.9rem" }} className="live-data-title">Postal Code</span>
                    </Col>
                    <Col sm={12} md={6} lg={6} xl={3} className="mb-3"  >
                        {isEditing ? (
                            <FormGroup controlId="postalCode" >
                                <FormControl
                                    maxLength={10} tabIndex="10"
                                    value={formData.postalCode}
                                    onChange={handleInputChange}
                                    className=" input-manage-field" />
                            </FormGroup>
                        ) : (
                            <span style={{ fontSize: "0.9rem" }} className='text-secondary mb-2'>{formData.postalCode || 'N/A'}</span>
                        )}
                    </Col>
                </Row>
                <Row className="ml-1">
                    <Col className="" md={4} lg={4} xl={2}  >
                        <span style={{ fontSize: "0.9rem" }} className="live-data-title">Payment Method</span>
                    </Col>
                    <Col sm={12} md={5} lg={5} xl={3} className="mb-3"  >

                        <FormGroup controlId="paymentMethod" className="d-flex">
                            <FormCheck
                                type="radio"
                                label="Invoice"
                                name="paymentMethod"
                                value="Invoice"
                                checked={formData.paymentMethod === 'Invoice'}
                                onChange={handlePaymentMethodChange}
                                //disabled={!isEditing ? "true" : ""}
                                disabled = "true"
                                className="mr-3"
                            />
                            <FormCheck
                                type="radio"
                                label="Credit Card"
                                name="paymentMethod"
                                value="Braintree"
                                checked={formData.paymentMethod === 'Braintree'}
                                onChange={handlePaymentMethodChange}
                                disabled="true"
                                className="mr-3"
                            />
                        </FormGroup>

                    </Col>
                    <Col className="" md={5} lg={5} xl={3}>
                        {isEditing && formData.paymentMethod === 'Braintree' ? (
                            <Button style={{ background: "transparent", border: "none", padding: 0, margin: 0 }} className='text-secondary mb-2'>
                                <a href={paymentUrl} target="_blank" style={{ textDecoration: "underline", color: themeColors.primary, fontSize: "0.9rem" }}>Add/Edit Credit Card on File</a>
                            </Button>
                        ) : (
                            <span></span>
                        )}
                    </Col>
                </Row>
                <Row>
                    {!isEditing ? (
                        <FormGroup lg={10} md={8}>
                            <Button as="input" type="button" value="Edit" style={{ float: 'right' }} onClick={handleEditClick} />
                        </FormGroup>
                    ) : (
                        <>
                            <FormGroup lg={10} md={8}>
                                <Button as="input" type="button" value="Save Changes" style={{ float: 'right', marginLeft: "5px" }} onClick={handleSaveChanges} />
                                <Button as="input" type="button" value="Cancel" style={{ float: 'right' }} onClick={handleCancelClick} />
                            </FormGroup>
                        </>
                    )}
                </Row>
            </Row>
        </>
    );
}

export default AccountBillingInformation;