import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import CustomTooltip from '../inventory/customTooltip';
import '@ag-grid-community/styles/ag-grid.min.css';
import '@ag-grid-community/styles/ag-theme-quartz.min.css';
import { Col, Button } from "react-bootstrap";
import { Card, CardBody, Spinner } from "reactstrap";
import CreateEditUserInfo from "./CreateEditUserInfo.js";
import { throwErrorIfNon200Response } from "../../helpers/utils";
import { toast } from "react-toastify";
import { LoadingModal } from '../ChangeServices/ChangeServiceCommon.js'
import '@ag-grid-community/styles/ag-grid.min.css';
import '@ag-grid-community/styles/ag-theme-quartz.min.css'

const UserInfoTable = ({ data }) => {
    const [userData, setUserData] = useState(data);
    const [showModal, setShowModal] = useState(false);
    const [editData, setEditData] = useState(null);
    const [Loading, setLoading] = useState(false);

    useEffect(() => {
        setUserData(data);
    }, [data])

    const onGridReady = (params) => {
        window.addEventListener('resize', () => {
            setTimeout(() => {
                params.api.sizeColumnsToFit();
            }, 0);
        });
        params.api.sizeColumnsToFit();
    };

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        tooltipComponent: 'customTooltip',
        flex: 1,
    };

    const EditButton = (params) => {
        return (
            <Button
                variant="light"
                size="sm"
                onClick={() => handleEdit(params.data)}
            >
                Edit
            </Button>
        );
    };

    const handleEdit = (userData) => {
        setEditData(userData);
        console.log("Edit");
        setShowModal(true);
    };

    const columnDefs = [
        {
            headerName: 'contact Id',
            headerTooltip: "Contact Id",
            field: 'pkContact',
            tooltipField: "Contact Id",
            cellStyle: { marginLeft: '16px' },
            hide: true,
            minWidth: 200,
        },
        {
            headerName: 'First Name',
            headerTooltip: "First Name",
            field: 'FirstName',
            tooltipField: "First Name",
            cellStyle: { marginLeft: '16px' },
            minWidth: 200,
        },
        {
            headerName: 'Last Name',
            headerTooltip: "Last Name",
            field: 'LastName',
            cellStyle: { marginLeft: '16px' },
            tooltipField: "Last Name",
            minWidth: 125
        },
        {
            headerName: 'Employee Id',
            headerTooltip: 'Employee Id',
            field: 'EmployeeID',
            tooltipField: "Employee ID",
            cellStyle: { marginLeft: '16px' },
            minWidth: 250
        },
        {
            headerName: 'Active',
            headerTooltip: 'Active',
            cellRenderer: params => {
                return (
                    <input
                        type="checkbox"
                        checked={params.value}
                        disabled={true}
                    />
                );
            },
            field: 'IsActive',
            tooltipField: "Active",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 110,
            minWidth: 80
        },
        {
            headerName: '',
            headerTooltip: 'Edit User',
            cellRenderer: EditButton,
            maxWidth: 120,
            minWidth: 100,
            flex: 1,
            cellStyle: { textAlign: 'center', border: 'none' }
           
        }
    ];

    const handleShowModal = () => {
        setEditData(null); 
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleUserSubmit = async (formData) => {
        try {
            let response;
            if (editData) {
                response = await fetch("api/AuthenticateUser/UpdateUser", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                    },
                    body: JSON.stringify({ ...formData }),
                });
            } else {
                response = await fetch(`api/AuthenticateUser/AddUser`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                    },
                    body: JSON.stringify({ ...formData }),
                });
            }

            const data = await response.json();
            if (data.statusType === "Complete") {
                const updatedUserData = editData
                    ? userData.map((user) => user.pkContact === formData.pkcontact ? formData : user)
                    : [...userData, formData];
                toast.success(data.errorMessage);
                setUserData(updatedUserData); 
                setShowModal(false);
            } else {
                let message = data.errorMessage;
                let userName = '';
                if (formData.hasOwnProperty("EmailAddress"))
                    userName = formData["EmailAddress"];
                if (message.includes("ClientLogin_uq")) {
                    message = `The username ${userName} already exists on another account and is unable to be used at this time.Please reach out to Advantix Support for assistance`;                   
                }
                toast.error(message || "Failed to save user data.");
            }
        } catch (error) {
            toast.error("An error occurred while saving user data. Please try again.");
            console.error("Error:", error);
        }
    };

    const handleForgotSubmit = () => {
        setLoading(true);
        fetch('/api/AuthenticateUser/ForgotPassword', {
            headers: { 'Content-Type': 'application/json' },
            method: 'post',
            body: JSON.stringify({ username: editData.EmailAddress })
        })
            .then(throwErrorIfNon200Response)
            .then((response) =>
                response.json().then((data) => {
                    if (data.statusType === "Complete") {
                        toast.success("Password reset completed.");
                    } else if (data.statusType === "Exception") {
                        toast.error(data.errorMessage || "An error occurred.");
                    } else {
                        setLoading(false)
                        toast.error("Password reset failed.");
                    }
                })
            )
            .catch((err) => {
                setLoading(false)
                toast.error("Unable to reset password. Please contact support.");
                console.error(`Error: ${err}`);
            })
            .finally(() => setLoading(false));
    };

    return (
        <>
            <LoadingModal showModal={Loading} >
            </LoadingModal>
            <Col lg={12} className="d-flex mb-4 justify-content-between">
                <h4 className="text-secondary">User Information</h4>
                <Button onClick={handleShowModal} as="input" type="button" value="Create New User">
                    
                </Button>
            </Col>

            <Card>
                <CardBody>
                    <div className="ag-theme-quartz grid">
                        <AgGridReact
                            onGridReady={onGridReady}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowData={userData}
                            frameworkComponents={{
                                customLoadingOverlay: () => <Spinner type="grow" />,
                                customNoRowsOverlay: () => <div>No UserInformation data</div>,
                                customTooltip: CustomTooltip
                            }}
                            loadingOverlayComponent='customLoadingOverlay'
                            noRowsOverlayComponent='customNoRowsOverlay'
                            pagination
                            paginationAutoPageSize
                            enableCellTextSelection
                            tooltipShowDelay={0}
                        />
                    </div>
                </CardBody>
            </Card>
            <CreateEditUserInfo
                show={showModal}
                onHide={handleCloseModal}
                onSubmit={handleUserSubmit}
                editData={editData}
                onForgotSubmit={handleForgotSubmit}
            />
        </>
    )
}

export { UserInfoTable };