import React, { useEffect, useState } from 'react';
import { Row, Col, FormGroup, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { LoadRatePlans, LoadingModal, formatStatusMessage } from '../ChangeServices/ChangeServiceCommon.js';
import Select from 'react-select';

function LoadProducts({ onRatePlanSelect, isCancelorSuspend, onProductSelect, isCancelLocation }) {
    const [products, setProducts] = useState({});
    const [ratePlanOptions, setRatePlanOptions] = useState([]);
    const masterAccountId = localStorage.getItem('masterAccountId');
    const sfAccountId = localStorage.getItem('salesforceId');
    const [selectedRatePlan, setSelectedRatePlan] = useState(null);
    const [showModal, setShowModal] = useState(false);
    let selectedProduct = '';

    useEffect(() => {
        fetchProductInfo();
    }, []);

    const fetchProductInfo = () => {
        setShowModal(true);
        fetch("api/OrderController/GetRatePlansProduct", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: "POST",
            body: JSON.stringify({
                sfAccountId: localStorage.getItem('salesforceId')
            })
        })
            .then(response => response.json())
            .then(data => {
                setShowModal(false);
                console.log('Fetched data:', data);
                setProducts(data);
            })
            .catch(error => {
                setShowModal(false);
                console.error('Error fetching Product info:', error);
            });
    };

    async function getPlans() {
        setShowModal(true);
        const accountInfo = { salesForceId: masterAccountId, sfAccountId: sfAccountId, OrderId: "", underlyingCarrier: selectedProduct };
        const response = await LoadRatePlans({ accountInfo: accountInfo });
        if (response.length > 0) {
            const ratePlanOptions = response.map((value) => ({
                label: value.description,
                value: value.skuNumber
            }));
            setRatePlanOptions(ratePlanOptions);
            setSelectedRatePlan(null);
            if (onRatePlanSelect) {
                onRatePlanSelect({ description: '', skuNumber: '' });
            }
            setShowModal(false);
        } else {
            setShowModal(false);
            toast.error(formatStatusMessage({ objStatus: response }));
        }
    }

    const productOptions = Object.keys(products)
        .filter(key => {
            if (isCancelLocation) {
                return products[key].includes('Single-Carrier SIM');
            }
            return isCancelorSuspend
                ? !products[key].includes('Data + Voice') && !products[key].includes('eSIM')
                : !products[key].includes('eSIM');
        })
        .map(key => ({
            value: key,
            label: isCancelorSuspend && products[key].includes('SmartSIM (Multi-Carrier)') ? 'SmartSIM (Multi-Carrier)' : products[key]

        }));

    const handleRatePlanProductSelect = (option) => {
        selectedProduct = option.value;
        if (!isCancelorSuspend) {
            getPlans();
        } else {
            onProductSelect(selectedProduct);
        }
    };

    const handleRatePlanSelect = (option) => {
        setSelectedRatePlan(option);
        if (onRatePlanSelect) {
            onRatePlanSelect({
                description: option.label,
                skuNumber: option.value
            });
        }
    };

    return (
        <>
            <LoadingModal showModal={showModal} />
            <Row>
                <Col lg={10} md={8}>
                    <FormGroup className="mb-3">
                        <Form.Label>Product</Form.Label>
                        <div>
                            <Select
                                className="basic-single"
                                isSearchable={false}
                                isDisabled={false}
                                onChange={(option) => handleRatePlanProductSelect(option)}
                                name="Product"
                                options={productOptions}
                            />
                        </div>
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                {!isCancelorSuspend &&
                    <Col lg={10} md={8}>
                        <FormGroup className="mb-3">
                            <Form.Label>Rate Plan</Form.Label>
                            <div>
                                <Select
                                    className="basic-single"
                                    isSearchable={false}
                                    isDisabled={false}
                                    name="RatePlan"
                                    options={ratePlanOptions}
                                    value={selectedRatePlan}
                                    onChange={handleRatePlanSelect}
                                />
                            </div>
                        </FormGroup>
                    </Col>}
            </Row>
        </>
    );
}

export default LoadProducts;