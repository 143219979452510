import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Button, FormGroup } from 'react-bootstrap';
import '../ChangeServices/ChangeServices.css';
import '../ChangeServices/ChangeServices.css';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { ValidateSIM, createOrder, LoadingModal, formatStatusMessage } from '../ChangeServices/ChangeServiceCommon.js';
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import LoadProducts from "../common/ProductSelection";

const CancelLocation = ({ usageData }) => {
    const [selectedAccount, setSelectedAccount] = useState("Select...");
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState('');
    const inputRef = useRef(null);
    const masterAccountId = localStorage.getItem('masterAccountId');
    const sfAccountId = localStorage.getItem('salesforceId');

    const defOption = {
        value: "1",
        label: localStorage.getItem("PortalUserAccountName"),
    };

    useEffect(() => {
        document.title = "CancelLocation";

        const currURL = new URL(window.location);
        if (currURL.searchParams.has("sim")) {
            const txtarea = inputRef.current;
            txtarea.value = currURL.searchParams.get("sim");
        }
    }, []);

    const handleAccountSelect = (option) => {
        setSelectedAccount(option.label);
    };

    const handleSubmit = async () => {
        const txtarea = inputRef.current;
        setShowModal(true);
        let invalidsims = "";
        const orderType = "CancelLocation";
        const toastProps = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        };

        try {
            if (!selectedProduct) {
                throw new Error("Please select Product before submitting an order.");
            }

            if (!txtarea.value) {
                throw new Error("Please enter SIM Number(s) before submitting an order.");
            }

            const formattedSimnum = String(txtarea.value).replace(/\r\n\t|\n|\r|\t|\s/gm, ",");
            const simEntity = {
                sfAccountId: sfAccountId,
                simNumbers: formattedSimnum.split(",").filter(value => value !== "").map(value => ({ SIM: value.trim(), IMEI: '', PlanSKU: '', Status: '' })),
                requiredStatus: "Active",
            };

            const resp = await ValidateSIM({ simEntity, requestType: orderType, product: selectedProduct });

            if (resp.response?.invalidsims) {
                throw new Error(resp.response.invalidsims);
            }

            if (resp.status === "success" && resp.response?.validsims) {
                const payload = {
                    // Payload fields
                    account: {
                        sfAccountId,
                        internalCustomerId: localStorage.getItem('InternalCustomerId'),
                        masterAccountId,
                    },
                    rateplanOrderItems: resp.response.validsims.split(",").map(value => ({ SIMNumber: value.trim(), "SIMQuantity": "1" })),
                    PortalUser: {
                        PortalUserName: localStorage.getItem('PortalUsername'),
                        FirstName: localStorage.getItem('PortalUsername').split(" ")[0],
                        LastName: localStorage.getItem('PortalUsername').split(" ")[1],
                        Email: localStorage.getItem('PortalUserEmail'),
                        PortalAccountName: localStorage.getItem('PortalUserAccountName'),
                        PortalAccountId: localStorage.getItem('PortalUserAccountId'),
                    },
                    "requestSource": "tlm"
                };

                const orderRequestCommonJSON = {
                    threadId: uuidv4(),
                    orderType,
                    provider: "Advantix",
                    payload,
                };

                const orderResp = await createOrder({ orderRequestCommonJson: orderRequestCommonJSON });

                if (orderResp.payload?.statusType === "Complete") {
                    txtarea.value = "";
                    toast.success(formatStatusMessage({ objStatus: orderResp }), toastProps);
                } else {
                    throw new Error(formatStatusMessage({ objStatus: orderResp }));
                }
            } else {
                throw new Error(formatStatusMessage({ objStatus: resp }));
            }
        } catch (error) {
            toast.error(formatStatusMessage({ objStatus: error }), toastProps);
        } finally {
            setShowModal(false);
        }
    };

    const handleProductSelect = (product) => {
        setSelectedProduct(product);
    };

    return (
        <>
            <LoadingModal showModal={showModal} />
            <Row>
                <Col>
                    <h3>Cancel Location</h3>
                    <hr />
                    <Form>
                        <Row>
                            <Col lg={10} md={8}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Account</Form.Label>
                                    <Select
                                        classNamePrefix="select"
                                        defaultValue={defOption}
                                        isDisabled={true}
                                        isSearchable={false}
                                        options={[defOption]}
                                        onChange={handleAccountSelect}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <LoadProducts isCancelorSuspend={true} onProductSelect={handleProductSelect} isCancelLocation={true} />

                        <Row>
                            <Col lg={10} md={8}>
                                <Form.Group className="mb-3">
                                    <Form.Label>SIM Numbers</Form.Label>
                                    <Form.Control ref={inputRef} as="textarea" rows={3} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={10} md={8}>
                                <FormGroup>
                                    <Button type="button" style={{ float: 'right' }} onClick={handleSubmit}>
                                        Submit
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

export default CancelLocation;
