import React, { Component, useState, useRef, useEffect } from 'react';
import { Row, Col, Button, FormGroup, } from 'react-bootstrap';
import './ChangeServices.css'
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
/*import PropTypes, { string, any } from 'prop-types';*/
import { ValidateSIM, createOrder, LoadingModal, formatStatusMessage } from './ChangeServiceCommon'
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import LoadProducts from "../common/ProductSelection";
import PhoneNumberReAssignment from './PhoneNumberReAssignment';
class SuspendOrCancel extends Component {
    constructor(props) {
        super(props);
        document.title = "Suspend/Carrier"
    }

    render() {
        return (
            <SuspendOrCancelContent usageData={this.props.usageData}></SuspendOrCancelContent >
        );
    }
}

function SuspendOrCancelContent({ children, usageData }) {
    const [suspend, setSuspend] = useState(true)
    const [cancel, setCancel] = useState(false)
    const [selectedAccount, setSelectedAccount] = useState("Select...")
    const [option, setOption] = useState("Suspend SIM");
    const inputRef = useRef(null);
    const masterAccountId = localStorage.getItem('masterAccountId');
    const sfAccountId = localStorage.getItem('salesforceId');
    const defOption = new Option(localStorage.getItem("PortalUserAccountName"), "1", true, true);
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [showPopUp, setShowPopUp] = useState(false);
    const [popupContent, setPopupContent] = useState("");
    const [validSIMs, setValidSIMs] = useState("");
    const [orderType, setOrderType] = useState("");
    const [webbingDataVoiceSIMs, setWebbingDataVoiceSIMs] = useState("");
    const history = useHistory();

    useEffect(() => {
        let currURL = new URL(window.location);
        if (currURL.searchParams.has("sim")) {
            let txtarea = inputRef.current
            txtarea.value = currURL.searchParams.get("sim");
        }

    }, [])

    let options = [defOption]
    let webbingDataVoiceSims = {};
    
    const type = 'radio'

    const handleRadioCheck = (e, suspend, cancel) => {
        console.dir(e);

        setSuspend(suspend)
        setCancel(cancel)
        if (suspend)
            setOption("Suspend SIM");

        if (cancel)
            setOption("Cancel SIM");
    }

    const handleAccountSelect = (option) => {
        setSelectedAccount(option.innerHTML);
    }
    const handleConfirm = async (selectedOption) => {
        console.log("Selected Option:", selectedOption);
        const payload = createPayload(validSIMs, orderType, webbingDataVoiceSims, selectedOption);
        await submitOrder(payload, orderType);
    };

    const validatePageDetails = async () => {
        const txtarea = inputRef.current;
        setShowModal(true);
        let invalidsims = "";
        let orderType = option === "Suspend SIM" ? "Suspend" : "Cancellation";
        const toastProps = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        };

        try {
            if (selectedProduct === "") {
                throw new Error("Please select Product before submitting an order.");
            }
            if (txtarea.value === "") {
                throw new Error("Please enter SIM Number(s) before submitting an order.");
            }

            let formattedSimnum = String(txtarea.value).replace(/\r\n\t|\n|\r|\t|\s/gm, ",");
            let simEntity = { sfAccountId: "", simNumbers: [], requiredStatus: "" };
            simEntity.sfAccountId = localStorage.getItem("salesforceId");
            const enteredsims = formattedSimnum.split(",");
            enteredsims.forEach((value) => {
                if (value !== "")
                    simEntity.simNumbers.push({ SIM: value.trim(), IMEI: "", PlanSKU: "", Status: "" });
            });
            simEntity.requiredStatus = "Active";

            const validResp = await ValidateSIM({ simEntity, requestType: orderType, product: selectedProduct });
            if (Object.hasOwn(validResp, 'response') && Object.hasOwn(validResp["response"], 'invalidsims')) {
                invalidsims = validResp["response"]["invalidsims"];
            }
            if (Object.hasOwn(validResp, 'response') && Object.hasOwn(validResp["response"], 'WebbingDataVoiceSims')) {
                webbingDataVoiceSims = validResp["response"]["WebbingDataVoiceSims"];
            }
            if (invalidsims !== "") {
                let invalidSimerr = new Error(invalidsims);
                invalidSimerr.name = "Invalid SIM(s) "
                throw invalidSimerr;
            }
            return {
                orderType,
                validsims: validResp.response.validsims || "",
                webbingDataVoiceSims: validResp.response.WebbingDataVoiceSims || {},
            };
        } catch (e) {
            alert(e);
            toast.error(formatStatusMessage({ objStatus: e }), toastProps);
            setShowModal(false);
            return null;
        }
    };

    const handleSubmit = async () => {
        const validationResponse = await validatePageDetails();
        if (!validationResponse) return;

        const { orderType, validsims, webbingDataVoiceSims } = validationResponse;

        if (validsims && webbingDataVoiceSims && webbingDataVoiceSims.length > 0) {
            setValidSIMs(validsims);
            setWebbingDataVoiceSIMs(webbingDataVoiceSims);
            setOrderType(orderType);
            setPopupContent(webbingDataVoiceSims);
            setShowPopUp(true);
        }
        else if (validsims) {
            const payload = createPayload(validsims, orderType, webbingDataVoiceSims, "");
            await submitOrder(payload, orderType);
        } else {
            setShowModal(false);
        }
    };

    const createPayload = (validsims, orderType, webbingDataVoiceSims = "", selectedOption = "") => {

        const webbingDataVoiceSIMsArray = Array.isArray(webbingDataVoiceSIMs)
            ? webbingDataVoiceSIMs
            : typeof webbingDataVoiceSIMs === "string"
                ? webbingDataVoiceSIMs.split(",").map((value) => value.trim())
                : [];

        const webbingDataVoiceSIMsSet = new Set(webbingDataVoiceSIMsArray);

        return {
            threadId: uuidv4(),
            createdDateTime: "",
            orderType,
            provider: "Advantix",
            payload: {
                portStatus: selectedOption !== "" ? selectedOption : null,
                account: {
                    sfAccountId,
                    internalCustomerId: localStorage.getItem('InternalCustomerId'),
                    masterAccountId,
                },
                rateplanOrderItems: validsims.split(",").map((value) => {
                    const trimmedSim = value.trim();
                    return {
                        SIMNumber: trimmedSim,
                        SIMQuantity: "1",
                        description: "",
                        ItemStatus: webbingDataVoiceSIMsSet.has(trimmedSim) ? "PortOutExisting" : null,
                    };
                }),
                PortalUser: {
                    PortalUserName: localStorage.getItem('PortalUsername'),
                    FirstName: localStorage.getItem('PortalUsername').split(" ")[0],
                    LastName: localStorage.getItem('PortalUsername').split(" ")[1],
                    Email: localStorage.getItem('PortalUserEmail'),
                    PortalAccountName: localStorage.getItem('PortalUserAccountName'),
                    PortalAccountId: localStorage.getItem('PortalUserAccountId'),
                },
                requestSource: "tlm",
            },
        };
    };

    const submitOrder = async (payload, orderType) => {
        const toastProps = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        };

        try {
            const orderResp = await createOrder({ orderRequestCommonJson: payload });
            if (Object.hasOwn(orderResp, "payload")) {
                if (orderResp.payload.statusType === "Complete") {
                    toast.success(formatStatusMessage({ objStatus: orderResp }), toastProps);
                } else {
                    toast.error(formatStatusMessage({ objStatus: orderResp }), toastProps);
                }
            }
        } catch (e) {
            toast.error(formatStatusMessage({ objStatus: e }), toastProps);
        } finally {
            setShowModal(false);
        }
    };

    const handleProductSelect = (product) => {
        setSelectedProduct(product);
    }

    const handleCloseModal = () => {
        setShowPopUp(false);
    }

    return (
        <>
            <LoadingModal showModal={showModal} />
            <Row>
                <Col>
                    <h3>Suspend or Cancel SIM/Rate Plan</h3>
                    <hr ></hr>
                    <Form>

                        <div key={`inline-${type}`} className="mb-3">
                            <Form.Check
                                inline
                                label="Suspend SIM"
                                name="group1"
                                type={type}
                                id={`inline-${type}-1`}
                                onClick={(e, suspend, cancel) => handleRadioCheck(e, !suspend, cancel)}
                                checked={!cancel}
                            />
                            <Form.Check
                                inline
                                label="Cancel SIM"
                                name="group1"
                                type={type}
                                id={`inline-${type}-2`}
                                onClick={(e, suspend, cancel) => handleRadioCheck(e, suspend, !cancel)}
                                checked={!suspend}
                            />
                        </div>
                        <Row>
                            <Col lg={10} md={8}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Account</Form.Label>
                                    <div>
                                        <Select
                                            className="basic-singles"
                                            classNamePrefix="select"
                                            defaultValue={defOption}
                                            isDisabled={true}
                                            isSearchable={false}
                                            name="Account"
                                            options={options}
                                            onChange={(option) => handleAccountSelect(option)}
                                        />
                                    </div>

                                </Form.Group>
                            </Col>
                        </Row>

                        <LoadProducts isCancelorSuspend={true} onProductSelect={handleProductSelect} isCancelLocation={false} ></LoadProducts>
                        <Row >
                            <Col lg={10} md={8} >
                                <Form.Group className="mb-3 " controlId="ControlTextarea1">
                                    <div style={{ marginTop: '15px' }} >
                                        <Form.Label >SIM Numbers</Form.Label>
                                        <Form.Control ref={inputRef} as="textarea" rows={3} />
                                    </div>

                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={10} md={8} >
                                <FormGroup >                                
                                  <Button as="input" type="button" value="Place Order" style={{ float: 'right' }} onClick={(e) => handleSubmit(e.target)} />                                
                                </FormGroup>
                            </Col>
                        </Row>


                    </Form>

                </Col>
            </Row>
            <PhoneNumberReAssignment show={showPopUp} onHide={handleCloseModal} dataVoiceSIMs={popupContent} onConfirm={handleConfirm}></PhoneNumberReAssignment>
        </>
    );
}

export default SuspendOrCancel