import 'bootstrap/dist/css/bootstrap.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import  App  from './App';
import registerServiceWorker from './registerServiceWorker';
import './css/theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min"

import {
    faHome,
    faSearch,
    faAngleRight,
    faFileInvoiceDollar,
    faList,
    faCaretRight,
    faFileExcel,
    faFilePdf,
    faSimCard,
    faDatabase, 
    faScrewdriverWrench,
    faRotate,
    faMagnifyingGlassChart,
    faUsersGear
} from "@fortawesome/free-solid-svg-icons";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

library.add(faHome, faSearch, faAngleRight, faFileInvoiceDollar, faList, faCaretRight, faFileExcel, faFilePdf, faSimCard, faDatabase, faScrewdriverWrench, faRotate, faMagnifyingGlassChart, faUsersGear);

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <App />
  </BrowserRouter>,
  rootElement
);

registerServiceWorker();
