/*import React, { useState } from 'react';*/
import Modal from 'react-bootstrap/Modal';
import { Row, Col } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import '@ag-grid-community/styles/ag-grid.min.css';
import '@ag-grid-community/styles/ag-theme-quartz.min.css';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import UsageGrid from './UsageSTable.js'
//usage modules
import DisplayOption from "./DisplayOptions"
import HighUsageInput from "./HighUsageInput"
import ReportDate from "./ReportDate"
import UsageFilter from "./UsageFilter"
import { throwErrorIfNon200Response, themeColors, generateErrorElementWithContactInfo } from "../../helpers/utils";
function UsageReports({ componentProps }) {
    const [exportData, setExportData] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const usageExportGridRef = useRef(null);
    const [dUsageState, setDUsageState] = useState({
        detailData: null,
        isDetailDataLoaded: false,
        mode: "summary",
        usageInput: '',
        modeTitle: 'Summary',
        reportStartDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        reportEndDate: new Date(),
        isStartDateOpen: false,
        isEndDateOpen: false,
        renderView: null,
        isFilterOptionsOpen: false,
        enableAssetWithUsage: false,
        isrenderViewOpen: false,
        filterOption: '',
        unfilterData: null,
        displayFilterButton: false,
        filterOptionElement: null,
        iconThreshold: 0,
        enableIconDataFilter: false,
        isOptionOpen: false,
        isStartDateChanged: false,
        isEndDateChanged: false,
        isModeChanged: false,
        totalRecords: 0,
        gridParams: null,
        loading: false,
    })
    const [usageState, setUsageState] = useState({ ...dUsageState });
    
    const onGridReady = useCallback((params, ustate = dUsageState) => {
       
        const dataSource = {
            rowCount: 1000,
            getRows: (params) => {
                let filterModel = params.filterModel;                
                let sortModel = params.sortModel;
                ustate.gridParams = params;
                ustate.loading = true;
                setUsageState({ ...ustate });
                fetch('api/SmartSIMUsageController/GetUsageReport', {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                    },
                    method: 'post',
                    body: JSON.stringify({
                        StartDate: ustate ? ustate.reportStartDate : new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                        EndDate: ustate ? ustate.reportEndDate : new Date(),
                        startrow: String(params.startRow),
                        endrow: String(params.endRow),
                        Mode: ustate ? ustate.mode : "summary",
                        treshold: ustate ? String(ustate.iconThreshold) : String(0) ,
                        PhoneNumber: String(Object.hasOwn(filterModel, 'PhoneNumber') ? filterModel["PhoneNumber"]["filter"] : ""),
                        RatePlanName: String(Object.hasOwn(filterModel, 'RatePlanName') ? filterModel["RatePlanName"]["filter"] : ""),
                        UserName: String(Object.hasOwn(filterModel, 'UserName') ? filterModel["UserName"]["filter"] : ""),
                        ServiceTypeID: String(Object.hasOwn(filterModel, 'ServiceTypeID') ? filterModel["ServiceTypeID"]["filter"] : ""),
                        Status: String(Object.hasOwn(filterModel, 'Status') ? filterModel["Status"]["filter"] : ""),
                        Usage: String(Object.hasOwn(filterModel, 'usage') ? filterModel["usage"]["filter"] : ""),
                        FilterOption: ustate ? String(ustate.filterOption): "",
                        SortOrder: String(Object.hasOwn(sortModel, '0') ? sortModel["0"]["sort"] : ""),
                        SortColumn: String(Object.hasOwn(sortModel, '0') ? sortModel["0"]["colId"] : ""),
                    })

                }).then(throwErrorIfNon200Response).then((response) => {
                    return response.json().then((usagedata) => {
                        
                        if (Array.isArray(usagedata) && usagedata.length > 0) {
                            let recordsCount = '', usageRecords = [];
                            recordsCount = usagedata[0]["TotalRowCount"];
                           
                            usageRecords = usagedata;
                            
                            ustate.loading = false;
                            ustate.totalRecords = Number(recordsCount)
                            setUsageState({ ...ustate })
                            params.successCallback(usageRecords, Number(recordsCount));
                        }
                        else {
                            ustate.loading = false;
                            setUsageState({ ...ustate })
                        }
                    });
                }).catch((ex) => {
                    ustate.loading = false;
                    ustate.detailData = [];
                    setUsageState({ ...ustate });
                    toast.error(generateErrorElementWithContactInfo("Error retrieving usage details."));
                });               
            },
        };
        params.api.setGridOption('datasource', dataSource);

    }, [setUsageState, dUsageState]);
    
    
    const handleFilterOption = (filterOption, data, modeChanged, mode) => {
        let ustate = usageState;
        let foption = filterOption
        
        if (mode !== "summary") {
            ustate.filterOption = 'All Assets';
            foption = 'All Assets';
            setUsageState(ustate);
        }
           
        switch (foption) {
            case 'All Assets':
                ustate.filterOption = 'All Assets'
                ustate.enableAssetWithUsage = false;
                setUsageState(ustate);
                onGridReady(ustate.gridParams, ustate);
                handleResetFilterOption()
                if (!modeChanged) {
                    handleOptionOpen()
                    ustate.isModeChanged = false;
                    setUsageState(ustate);                        
                    }
               
                break;
            case 'Assets with Usage':
                ustate.filterOption = 'Assets with Usage'
                ustate.enableAssetWithUsage = false;
                onGridReady(ustate.gridParams, ustate);
                handleResetFilterOption()
                if (!modeChanged) {
                    handleOptionOpen()
                    ustate.isModeChanged = false;
                    setUsageState(ustate);
                }
                
                break;
            case 'Assets with Zero':
                ustate.filterOption = 'Assets with Zero'               
                onGridReady(ustate.gridParams, ustate);
                handleResetFilterOption()
                if (!modeChanged) {
                    handleOptionOpen()
                    ustate.isModeChanged = false;
                    setUsageState(ustate);
                }
               
                break;
            default:
                handleOptionOpen()
                break;

        }
    }
    const handleStartDateSelection = (date) => {
        let ustate = usageState;
        ustate.reportStartDate = date;
        ustate.isStartDateChanged = true;
        setUsageState({ ...ustate });
    }

    const handleEndDateSelection = (date) => {
        let ustate = usageState;
        ustate.reportEndDate = date;
        ustate.isEndDateChanged = true;  
        setUsageState({ ...ustate });
    }




    const handleFilter = (event) => {

        event.preventDefault()
        let ustate = usageState;
        let stDateChanged = usageState.isStartDateChanged
        let endDateChanged = usageState.isEndDateChanged
        if (stDateChanged) {
            ustate.isStartDateChanged = false;
            setUsageState({ ...ustate });
        }            
        if (endDateChanged) {
            ustate.isEndDateChanged = false;
            setUsageState({ ...ustate });
        }
        onGridReady(ustate.gridParams, ustate);
       
    }

    const setFilterOption = (event) => {
        const input = event.target;
        const labelValue = input.getAttribute('data-name')
        let ustate = usageState;
        if (labelValue === 'Assets with Usage') {
            input.value = true
            ustate.filterOption = labelValue;
            ustate.enableAssetWithUsage = true;
            ustate.displayFilterButton = true;
            ustate.filterOptionElement = event.target
            setUsageState({ ...ustate });
        }
        else if (labelValue == "Assets with Zero") {
            ustate.filterOption = labelValue;
            ustate.enableAssetWithUsage = false;
            ustate.displayFilterButton = true;
            ustate.filterOptionElement = event.target
            setUsageState({ ...ustate }); 
        }
        else {
            input.checked = true
            ustate.filterOption = labelValue;
            ustate.enableAssetWithUsage = false;
            ustate.displayFilterButton = true;
            ustate.filterOptionElement = event.target
            setUsageState({ ...ustate });            
        }
    }
    const handleResetFilterOption = () => {
        let ustate = usageState;
        ustate.isFilterOptionsOpen = !ustate.isFilterOptionsOpen;
        ustate.displayFilterButton = false;
        setUsageState({ ...ustate });
       
    }


    //const handleReportDateSearch = () => {
    //    if (moment(this.state.reportEndDate).isAfter(this.state.reportStartDate)) {
    //        this.fetchDetailData()
    //    }
    //}

    const handleRenderView = () => {
        
        let ustate = usageState;
        ustate.isrenderViewOpen = !ustate.isrenderViewOpen;
        setUsageState({ ...ustate });
    }
    const handleRenderViewSelection = (modeTitle, mode) => {
        
        let ustate = usageState;
        ustate.mode = mode;
        ustate.modeTitle = modeTitle;
        ustate.isrenderViewOpen = false;
        ustate.isModeChanged = true;

        setUsageState({ ...ustate });
        onGridReady(ustate.gridParams, ustate);        
    }

    const handleUsageInput = (event) => {
        
        let ustate = usageState;
        let enableicon = event.target.id === 'iconThreshold' ? true : false;
        ustate.iconThreshold = event.target.value === '' ? 0 : event.target.value;
        ustate.enableIconDataFilter = enableicon
        ustate.filterOption = enableicon ? 'Assets with Usage' : ustate.filterOption;
        setUsageState({ ...ustate });
       
    }
    //const handleResetFilter = () => {
    //    const tempData = this.state.unfilterData
    //    this.setState({ detailData: tempData, displayFilterButton: false })
    //}

    const handleOptionOpen = () => {
        
        let ustate = usageState;
        ustate.isOptionOpen = !ustate.isOptionOpen
        setUsageState({ ...ustate } );        
    }
   const  Opentoast = () => {
       
        toast.info(
            <div style={{ display: "flex", alignItems: "center" }}>
                <div onClick={Opentoast} style={{ width: '2rem', height: "auto", position: 'relative' }} className="usage-pulse">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ fill: themeColors.primary, }} className="usage-info"  >
                        <path d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 128c17.67 0 
                            32 14.33 32 32c0 17.67-14.33 32-32 32S224 177.7 224 160C224 142.3 238.3 128 256 128zM296 384h-80C202.8 384 192 373.3 192 360s10.75-24 
                            24-24h16v-64H224c-13.25
                            0-24-10.75-24-24S210.8 224 224 224h32c13.25 0 24 10.75 24 24v88h16c13.25 0 24 10.75 24 24S309.3 384 296 384z" />
                    </svg>
                </div>
                <span style={{ marginLeft: "1rem", fontWeight: "bold", color: '#177198' }}>To change the date range of this data, click the
                    <svg style={{ width: '1rem', cursor: 'pointer', fill: themeColors.primary, marginRight: '0.3rem', marginLeft: '0.3rem' }} type="button"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M3.853 54.87C10.47 40.9 24.54 32 40 32H472C487.5 32 
                            501.5 40.9 508.1 54.87C514.8 68.84 512.7 85.37 502.1 97.33L320 320.9V448C320 460.1 313.2 471.2 302.3 476.6C291.5 482 278.5 480.9 268.8 473.6L204.8
                            425.6C196.7 419.6 192 410.1 192 400V320.9L9.042 97.33C-.745 85.37-2.765 68.84 3.854 54.87L3.853 54.87z" />
                    </svg> icon on the right side of the screen</span>

            </div>, {

            position: 'top-center',
            style: { position: "absolute", width: '100%', zIndex: 999, height: '5rem', top: '3rem', },
            toastId: 'customId',
            autoClose: 5000,
            pauseOnHover: false,
            onOpen: () => {
                let toastContent = document.querySelector('#customId');
                let toastButton = document.querySelector('.Toastify__close-button')
                toastContent.style.setProperty("background-color", "#D4F2FF", "important");
                toastContent.style.setProperty("border-radius", "10rem", "important");
                let parent = document.querySelector('#container-toats');
                if (toastContent) {
                    parent.appendChild(toastContent)
                    if (toastButton) {
                        toastButton.style.setProperty("align-self", "center")

                    }
                }
            }
        });


    }

    const [exportColumnDefs, setExportColumnDefs] = useState([
        { headerName: 'Date', field: 'Usagemonth' },
        { headerName: 'SIM Number', field: 'PhoneNumber' },
        { headerName: 'Usage(MB)', field: 'UsageQuantity' },
        { headerName: 'Rate Plan', field: 'RatePlanName' },
        { headerName: 'Service Type', field: 'ServiceType' },
        { headerName: 'User Name', field: 'Username' },
        { headerName: 'Status', field: 'Status' },
    ])

    const onBtExport = useCallback(() => {
        try {
            
            setShowLoading(true);
           
            fetch('/api/SmartSIMUsageController/GetUsageReport', {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                },
                cache: "default",
                body: JSON.stringify({
                    StartDate: new Date(usageState.reportStartDate),
                    EndDate: new Date(usageState.reportEndDate),
                    startrow: String(0),
                    endrow: String(usageState.totalRecords),
                    Mode: String(usageState.mode),
                    treshold: String(0),
                    PhoneNumber: "",
                    RatePlanName: "",
                    UserName: "",
                    ServiceTypeID: "",
                    Status: "",
                    Usage: "",
                    FilterOption: "",
                    SortOrder: "",
                    SortColumn: "",
                })
            })
                .then((result) => result.json()).then((exportgriddata) => {

                    setExportData(exportgriddata);

                    setTimeout(function () {
                        usageExportGridRef.current.api.exportDataAsCsv({
                            fileName: `Usage_Report`,
                            suppressQuotes: true,
                            processCellCallback: (cell) => {
                                let uname = cell["node"]["data"]["Username"].replace(/,/g, "");
                               
                                if (cell.column.colId === "PhoneNumber") {
                                    return `'${cell.node.data.PhoneNumber}'`;
                                }
                                else if (cell.column.colId === "Username") {
                                    return uname
                                }
                                else if (cell.column.colId === "RatePlanName")
                                    return cell.value
                                else if (cell.column.colId === "UsageQuantity")
                                    return cell.value
                                else if (cell.column.colId === "ServiceType")
                                    return cell.value
                                else if (cell.column.colId === "Status")
                                    return cell.value
                                else if (usageState.mode !== "summary" && cell.column.colId === "Usagemonth")
                                    return cell.value
                                    else
                                    return "";
                            },
                            processHeaderCallback: (params) => {
                                
                                if (params["column"]["colId"] === "PhoneNumber")
                                    return params["column"]["colDef"]["headerName"];
                                else if (params["column"]["colId"] === "Username")
                                    return params["column"]["colDef"]["headerName"];
                                else if (params["column"]["colId"] === "RatePlanName")
                                    return params["column"]["colDef"]["headerName"];
                                else if (params["column"]["colId"] === "UsageQuantity")
                                    return params["column"]["colDef"]["headerName"];
                                else if (params["column"]["colId"] === "ServiceType")
                                    return params["column"]["colDef"]["headerName"];
                                else if (params["column"]["colId"] === "Status")
                                    return params["column"]["colDef"]["headerName"];
                                else if (usageState.mode !== "summary" && params.column.colId === "Usagemonth")
                                    return params["column"]["colDef"]["headerName"];
                                else
                                    return "";
                                   
                            }
                        });
                        setExportData([]);
                    }, 500)

                    setShowLoading(false);
                    


                }).catch(err => {
                    console.log(err);
                    setShowLoading(false);
                    setExportData([]);

                })
        } catch (error) {
            setShowLoading(false);
            console.error("Error during CSV export:", error);
            toast.error(error);
        }
    }, [setExportData, usageState.totalRecords, setShowLoading, usageState.reportStartDate, usageState.reportEndDate, usageState.mode]);
    return (
        <>
            <Modal id="ChangeServicesLoader"
                show={showLoading}
                backdrop={true}
                keyboard={false}
                centered
            >
                <Spinner animation="grow" role="status">
                </Spinner>
            </Modal>
            <div id="container-toats" style={{ position: 'relative' }}>
            </div>
            <Row>
                <Col>
                    <DisplayOption isOptionOpen={usageState.isOptionOpen}
                        closeOption={handleOptionOpen} reportStartDate={usageState.reportStartDate}
                        reportEndDate={usageState.reportEndDate}
                        handleFilter={handleFilter} >

                        <Row style={{ alignItems: "flex-end" }}>
                            {usageState.mode === 'summary' && (
                                <Col lg={7} md={12} xl={4} xxl={3} xs={12}  >
                                    <UsageFilter
                                        filterOption={usageState.filterOption}
                                        
                                        setFilterOption={setFilterOption}
                                        
                                         />
                                </Col>
                            )}

                            <ReportDate handleStartDateSelection={handleStartDateSelection}
                                handleEndDateSelection={handleEndDateSelection}
                                reportstartDate={usageState.reportStartDate}
                                reportEndDate={usageState.reportEndDate} />

                            {usageState.mode === 'summary' && (
                                <HighUsageInput mode={usageState.mode} iconThreshold={usageState.iconThreshold}
                                    handleUsageInput={handleUsageInput} />
                            )}
                        </Row>
                    </DisplayOption>
                    <UsageGrid
                        isOptionOpen={usageState.isOptionOpen}
                        Opentoast={Opentoast}
                        modeTitle={usageState.modeTitle}
                        isrenderViewOpen={usageState.isrenderViewOpen}
                        handleRenderView={handleRenderView}
                        handleRenderViewSelection={handleRenderViewSelection}
                        detailData={usageState.detailData}
                        mode={usageState.mode}
                        handleOption={handleOptionOpen}
                        threshold={usageState.iconThreshold}
                        onGridReady={onGridReady}
                        loading={usageState.loading}
                        onBtExport={onBtExport}
                    >
                    </UsageGrid>
                </Col>
            </Row> 
            <Row>
                <Col>
                    <AgGridReact
                        ref={usageExportGridRef}
                        columnDefs={usageState.mode === 'summary' ? exportColumnDefs.toSpliced(0, 1) : exportColumnDefs}
                        rowData={exportData}
                    />
                </Col>
            </Row>
        </>
    )
}

export default UsageReports;