import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';

let origin;
let changeplanURL;
let changeCarrierUrl;
let suspendOrCancelURL;
let reactivateSimUrl;
let sendMessageUrl;
let viewMessagelogsURL;
let simNum;
let cancelLocationlogsURL;

const ChangeService = (simData) => {
    const isGridMenu = Object.keys(simData).length > 0 && Object.hasOwn(simData, ('simData')) && simData["simData"]["PhoneNumber"] ? true : false;
  
    if (isGridMenu)
        simNum = simData.simData.PhoneNumber;
    else 
        simNum = Object.hasOwn(simData, ('simData')) ? simData["simData"]["PhoneNumber"] : window.location.pathname.split("/")[2];            
        
    
    origin = document.location.origin;
    changeplanURL = new URL(origin.concat("/").concat("ChangePlan/?sim=").concat(simNum));
    changeCarrierUrl = new URL(origin.concat("/").concat("changecarrier/?sim=").concat(simNum));
    suspendOrCancelURL = new URL(origin.concat("/").concat("SuspendOrCancel/?sim=").concat(simNum));
    reactivateSimUrl = new URL(origin.concat("/").concat("ReactivateSIM/?sim=").concat(simNum));
    sendMessageUrl = new URL(origin.concat("/").concat("SendMessageToDevice/?sim=").concat(simNum));
    viewMessagelogsURL = new URL(origin.concat("/").concat("ViewSMSLogs/?sim=").concat(simNum));
    cancelLocationlogsURL = new URL(origin.concat("/").concat("CancelLocation/?sim=").concat(simNum));
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Change Service</Popover.Header>
            <Popover.Body>
                <div>
                    <div>
                        <a href={changeplanURL.href} style={{ textDecoration: "none" }}>Change RatePlan</a>
                    </div>
                    <div>
                        <a href={changeCarrierUrl.href} style={{ textDecoration: "none" }} >Change Preferred Carrier</a>
                    </div>
                    <div>
                        <a href={suspendOrCancelURL.href} style={{ textDecoration: "none" }} >Suspend Or Cancel</a>
                    </div>
                    <div>
                        <a href={reactivateSimUrl.href} style={{ textDecoration: "none" }} >Reactivate SIM</a>
                    </div>
                </div>

            </Popover.Body>
            <Popover.Header as="h3">Troubleshooting</Popover.Header>
            <Popover.Body>
                <div>
                    <div>
                        <a href={sendMessageUrl.href} style={{ textDecoration: "none" }}>Send Message</a>
                    </div>
                    <div>
                        <a href={viewMessagelogsURL.href} style={{ textDecoration: "none" }} >View Message Logs</a>
                    </div>
                    <div>
                        <a href={cancelLocationlogsURL.href} style={{ textDecoration: "none" }} >Cancel Location</a>
                    </div>
                </div>

            </Popover.Body>
        </Popover>
    );

    return (
        <>
            <OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose={true} >
                <Button style={{ marginLeft: "2%" }} variant="light" className="d-inline-flex align-items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height={isGridMenu ? "16" : "31"} fill="currentColor" class="bi bi-three-dots" viewBox="0 0 16 16">
                        <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z" />
                    </svg>
                </Button>
            </OverlayTrigger>
        </>

    );
}

export default ChangeService;