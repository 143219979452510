
import { AgGridReact } from 'ag-grid-react';
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import { ModuleRegistry } from '@ag-grid-community/core';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import "./InventoryTable.css";
import '@ag-grid-community/styles/ag-grid.min.css';
import '@ag-grid-community/styles/ag-theme-quartz.min.css';
import React, { StrictMode, useCallback, useMemo, useState, useRef, useEffect } from 'react';
import SimLink from "./SimLink";
import CustomTooltip from './customTooltip';
import OverlayMenu from '../common/ContextMenu';
import { Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { Row, Col, Form } from 'react-bootstrap';
import { string } from 'prop-types';

ModuleRegistry.registerModules([InfiniteRowModelModule]);

const rowSelection = { mode: 'multiRow', checkboxes: false, headerCheckbox: false };
function InventoryAGGrid() {
    const [rowData, setRowData] = useState([]);
    const [loading, setloading] = useState(false);
    const inventoryGridRef = useRef(null);
    const inventoryExportGridRef = useRef(null);
    const [totalRecords, setTotalRecords] = useState('');
    const [exportData, setExportData] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [showCancelled, setShowCancelled] = useState(false);
    const [gridparams, setgridparams] = useState(null);

    const onGridReady = useCallback((params, cancelled = false) => {

        setgridparams(params);

        let rowsThisPage = [];
        const dataSource = {
            rowCount: 1000,
            getRows: (params) => {

                setloading(true);

                let filterModel = params.filterModel;
                let sortModel = params.sortModel;

                fetch('/api/SmartSIMInventoryController/GetInventoryData', {
                    method: "post",
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                    },
                    cache: "default",
                    body: JSON.stringify({
                        startrow: String(params.startRow),
                        endrow: String(params.endRow),
                        PhoneNumber: String(Object.hasOwn(filterModel, 'PhoneNumber') ? filterModel["PhoneNumber"]["filter"] : ""),
                        UserName: String(Object.hasOwn(filterModel, 'UserName') ? filterModel["UserName"]["filter"] : ""),
                        RatePlanName: String(Object.hasOwn(filterModel, 'RatePlanName') ? filterModel["RatePlanName"]["filter"] : ""),
                        Usage: String(Object.hasOwn(filterModel, 'Usage') ? filterModel["Usage"]["filter"] : ""),
                        Status: String(Object.hasOwn(filterModel, 'Status') ? filterModel["Status"]["filter"] : ""),
                        SortOrder: String(Object.hasOwn(sortModel, '0') ? sortModel["0"]["sort"] : ""),
                        SortColumn: String(Object.hasOwn(sortModel, '0') ? sortModel["0"]["colId"] : ""),
                        ShowCancelled: String(cancelled)

                    })
                })
                    .then((result) => result.json()).then((data2) => {

                        let recordsCount = '', inventoryRecords = '';
                        if (Object.hasOwn(data2, "RecordsCount")) {
                            setTotalRecords(data2["RecordsCount"]);
                            recordsCount = data2["RecordsCount"];
                        }


                        if (Object.hasOwn(data2, "InventoryData"))
                            inventoryRecords = data2["InventoryData"];

                        setloading(false);
                        params.successCallback(inventoryRecords, Number(recordsCount));

                    }).catch(err => {
                        setloading(false);
                        params.successCallback([], 0);

                    })
            },
        };
        params.api.setGridOption('datasource', dataSource);

    }, [setloading, setTotalRecords, showCancelled]);

    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            resizable: true,
            filter: true,
            tooltipComponent: 'CustomTooltip',
            flex: 1,
        };
    }, []);

    const formatNumber = (number) => {
        return Math.floor(number)
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    };

    const usageFormatter = (params) => {
        if (!isNaN(params.value))
            return formatNumber(params.value) + ' MB'
        else
            return ""
    };

    const usernameFormatter = (params) => params.value?.split('|')[0];
    const [columnDefs, setColDefs] = useState([
        {
            headerName: 'SIM Number',
            headerTooltip: "SIM Number",
            field: 'PhoneNumber',
            valueGetter: 'node.id',
            tooltipField: "PhoneNumber",
            minWidth: 200,
            height: 40,
            cellRenderer: (params) => {
                if (params.value === undefined)
                    // when no node id, display the spinner image
                    //return (<><Spinner animation="border" size="sm" /> <span>Loading...</span></>);
                    return "";
                else
                    return <Link style={{ paddingLeft: '1rem' }} to={`/inventorydetails-live-data/${params["data"]["PhoneNumber"]}`}>{params["data"]["PhoneNumber"]}</Link>;

            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'User',
            headerTooltip: "User",
            field: 'UserName',
            valueFormatter: usernameFormatter,
            cellStyle: { marginLeft: '16px' },
            tooltipField: "UserName",
            minWidth: 125,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Rate Plan',
            headerTooltip: 'Rate Plan',
            field: 'RatePlanName',
            tooltipField: "RatePlanName",
            cellStyle: { marginLeft: '16px' },
            minWidth: 250,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'MRC',
            headerTooltip: 'MRC',
            field: 'MRC',
            filter: false,
            tooltipField: "MRC",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 110,
            minWidth: 80,

        },
        {
            headerName: 'Usage',
            headerTooltip: 'Usage',
            field: 'Usage',
            valueFormatter: usageFormatter,
            filter: false,
            tooltipField: "Usage",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 110,
            minWidth: 80,
        },
        {
            headerName: 'Status',
            headerTooltip: 'Status',
            field: 'Status',
            tooltipField: "Status",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 110,
            minWidth: 80,
            filter: true,
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: '',
            headerTooltip: "Change Service",
            maxWidth: 80,
            valueGetter: 'node.id',
            field: "PhoneNumber",
            minWidth: 70,
            flex: 1,
            cellRenderer: (params) => {

                if (params.value !== undefined)
                    return <OverlayMenu simData={params["data"]}></OverlayMenu>;
                else
                    return "";
            },
            cellStyle: { border: 'none' },
            filter: false
        },
    ]);
    const columnExportDefs = useState([
        { headerName: 'SIM Number', field: 'PhoneNumber' },
        { headerName: 'User', field: 'UserName' },
        { headerName: 'Rate Plan', field: 'RatePlanName' },
        { headerName: 'MRC', field: 'MRC' },
        { headerName: 'Usage', field: 'Usage' },
        { headerName: 'Status', field: 'Status' },
    ])

    const onBtExport = useCallback(() => {
        try {
            //const { CustomerName } = usageData;
            setShowLoading(true);

            fetch('/api/SmartSIMInventoryController/GetInventoryData', {
                method: "post",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('authToken')
                },
                cache: "default",
                body: JSON.stringify({
                    startrow: String(0),
                    endrow: String(totalRecords),
                    PhoneNumber: "",
                    UserName: "",
                    RatePlanName: "",
                    Usage: "",
                    Status: "",
                    SortOrder: "",
                    SortColumn: "",
                    ShowCancelled: String(showCancelled)
                })
            })
                .then((result) => result.json()).then((exportgriddata) => {

                    setExportData(exportgriddata["InventoryData"]);

                    setTimeout(function () {
                        inventoryExportGridRef.current.api.exportDataAsCsv({
                            fileName: `Inventory_Report`,
                            suppressQuotes: true,
                            processCellCallback: (cell) => {
                                let uname = cell["node"]["data"]["UserName"].replace(/,/g, "");

                                if (cell.column.colId === "0") {
                                    return [`'${cell.node.data.PhoneNumber}'`,
                                        uname,
                                    cell["node"]["data"]["RatePlanName"],
                                    cell["node"]["data"]["MRC"],
                                    cell["node"]["data"]["Usage"],
                                    cell["node"]["data"]["Status"]];
                                }
                                else
                                    return "";
                            },
                            processHeaderCallback: (params) => {

                                if (params.column.colId === "0")
                                    return ["PhoneNumber", "UserName", "RatePlanname", "MRC", "Usage", "Status"];
                            }
                        });
                        setExportData([]);
                    }, 500)

                    setShowLoading(false);
                }).catch(err => {
                    console.log(err);
                    setShowLoading(false);
                    setExportData([]);

                })
        } catch (error) {
            setShowLoading(false);
            console.error("Error during CSV export:", error);
            toast.error(error);
        }
    }, [setExportData, totalRecords, setShowLoading, showCancelled]);
    return (
        <>

            <Modal id="ChangeServicesLoader"
                show={showLoading}
                backdrop={true}
                keyboard={false}
                centered
            >
                <Spinner animation="grow" role="status">
                </Spinner>
            </Modal>
            <Row>
                <Col>
                    <div style={{ display: 'flex' }}>
                        <div >
                            <h4 className="text-secondary">Inventory</h4>
                        </div>
                        <div style={{ marginLeft: 'auto', visibility: 'vissible' }}>
                            <Form.Check 
                                type="switch"
                                id="custom-showcancelled-switch"
                                label="Show Cancelled SIMs"
                                checked={showCancelled}
                                onChange={(e) => {
                                    setShowCancelled(e.target.checked);
                                    let customGridParams = gridparams;
                                    customGridParams.startrow = 0;
                                    customGridParams.endrow = 100;
                                    onGridReady(customGridParams, e.target.checked);
                                }}
                            />
                        </div>
                        <div >
                            <FontAwesomeIcon
                                onClick={onBtExport}
                                icon={faFileExcel}
                                style={{ fontSize: '1.7rem', cursor: 'pointer', marginLeft: '1rem', top: '0.3rem' }}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div
                        className="ag-theme-quartz"
                        style={{ height: 500 }}
                    >
                        <AgGridReact
                            ref={inventoryGridRef}
                            columnDefs={columnDefs}
                            onGridReady={onGridReady}
                            loading={loading}
                            pagination={true}
                            defaultColDef={defaultColDef}
                            rowBuffer={0}
                            rowSelection={rowSelection}
                            rowModelType={'infinite'}
                            cacheBlockSize={100}
                            cacheOverflowSize={2}
                            maxConcurrentDatasourceRequests={1}
                            infiniteInitialRowCount={100}
                            maxBlocksInCache={10}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AgGridReact
                        ref={inventoryExportGridRef}
                        columnDefs={columnExportDefs}
                        rowData={exportData}
                    />
                </Col>
            </Row>
        </>
    );
}

export default InventoryAGGrid;