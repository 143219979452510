import { AgGridReact } from 'ag-grid-react';
import { Row, Col } from 'react-bootstrap';
import { ModuleRegistry } from '@ag-grid-community/core';
import { InfiniteRowModelModule } from '@ag-grid-community/infinite-row-model';
import '@ag-grid-community/styles/ag-grid.min.css';
import '@ag-grid-community/styles/ag-theme-quartz.min.css';
import React, { useMemo, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import UsageSelector from "./UsageSelector"
import UsageCellRenderSim from "./UsageCellRenderSim"
import OverlayMenu from '../common/ContextMenu';

ModuleRegistry.registerModules([InfiniteRowModelModule]);
const rowSelection = { mode: 'multiRow', checkboxes: false, headerCheckbox: false };

function UsageGrid({ isOptionOpen, Opentoast, usageData, handleOption, detailData, mode, threshold, modeTitle, isrenderViewOpen,
    handleRenderView, handleAlertOpen, handleRenderViewSelection, onGridReady, loading, onBtExport }) {

    const usageGridRef = useRef(null)


    function usernameFormatter(params) {
        return params.value ? params.value.split('|')[0] : "";
    }
    function UsageValueGetter(params) {

        if (!isNaN(params.value)) {
            let data = parseFloat(params.data.UsageQuantity)
            return data.toFixed(2);
        }
        else
            return ""

    }
    const defaultColDef = useMemo(() => {
        return {
            sortable: true,
            resizable: true,
            filter: true,
            tooltipComponent: 'CustomTooltip',
            flex: 1,
        };
    }, []);



    const [columnDefs, setColDefs] = useState([
        {
            headerName: 'Date',
            headerTooltip: "Date",
            field: 'Usagemonth',
            tooltipField: "Usagemonth",
            cellStyle: { marginLeft: '16px' },
            minWidth: 100,

        },

        {
            headerName: 'SIM Number',
            headerTooltip: "SIM Number",
            field: 'PhoneNumber',
            maxWidth: 220,
            minWidth: 200,
            valueGetter: 'node.id',
            cellRenderer: (params) => {

                if (params.value !== undefined)
                    return <UsageCellRenderSim simData={params["data"]} props={{ mode, threshold }} />;
                else
                    return "";
            },

            cellRendererParams: {
                mode: mode,
                threshold: threshold
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }

        },
        {
            headerName: 'Usage(MB)',
            headerTooltip: 'Usage',
            field: 'UsageQuantity',
            filter: 'agNumberColumnFilter',
            tooltipField: "UsageQuantity",
            cellStyle: { marginLeft: '16px' },
            accentedSort: true,
            valueGetter: 'node.id',
            minWidth: 120,
            cellRenderer: (params) => {

                if (params.value !== undefined)
                    return params.data.UsageQuantity + " MB"
                else
                    return "";
            },
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }
        },
        {
            headerName: 'Rate Plan',
            headerTooltip: 'Rate Plan',
            field: 'RatePlanName',
            tooltipField: "RatePlanName",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 300,
            minWidth: 270,
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }

        },

        {
            headerName: 'Service Type',
            headerTooltip: 'Service Type',
            field: 'ServiceType',
            tooltipField: "Serivice Type",
            cellStyle: { marginLeft: '16px' },
            maxWidth: 250,
            minWidth: 130,
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }

        },
        {
            headerName: 'User Name',
            headerTooltip: "User",
            field: 'Username',
            valueFormatter: usernameFormatter,
            cellStyle: { marginLeft: '16px' },
            tooltipField: "Username",
            minWidth: 120,
            filter: 'agTextColumnFilter',

        },
        {
            headerName: 'Status',
            headerTooltip: "Status",
            field: 'Status',
            valueFormatter: usernameFormatter,
            cellStyle: { marginLeft: '16px' },
            tooltipField: "Status",
            minWidth: 120,
            filter: 'agTextColumnFilter',
            filterParams: {
                filterOptions: ['contains'],
                maxNumConditions: 1,
            }

        },
        {
            headerName: '',
            headerTooltip: "Change Service",
            minWidth: 80,
            valueGetter: 'node.id',
            flex: 1,
            filter: false,
            cellRenderer: (params) => {

                if (params.value !== undefined)
                    return <OverlayMenu simData={params["data"]}></OverlayMenu>;
                else
                    return "";
            },
            cellStyle: { border: 'none' }

        },
    ]);

    return (
        <>
            <Row className='d-flex'>
                <Col lg={12} style={{ justifyContent: "", alignItems: "flex-start" }} className="d-flex ">
                    <h4 className="text-secondary usage-header"  >Usage</h4>

                    <UsageSelector
                        Opentoast={Opentoast}
                        modeTitle={modeTitle}
                        isrenderViewOpen={isrenderViewOpen}
                        handleRenderView={handleRenderView}
                        handleAlertOpen={handleAlertOpen}
                        handleRenderViewSelection={handleRenderViewSelection} />
                    <div style={{ display: "flex", width: "100%", justifyContent: 'end' }}>
                        <svg onClick={handleOption} className="filter-icon-usage" style={{ width: '1.7rem', cursor: 'pointer', fill: "#5e6e82" }} type="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M3.853 54.87C10.47 40.9 24.54 32 40 32H472C487.5 32 
                            501.5 40.9 508.1 54.87C514.8 68.84 512.7 85.37 502.1 97.33L320 320.9V448C320 460.1 313.2 471.2 302.3 476.6C291.5 482 278.5 480.9 268.8 473.6L204.8
                            425.6C196.7 419.6 192 410.1 192 400V320.9L9.042 97.33C-.745 85.37-2.765 68.84 3.854 54.87L3.853 54.87z" />
                        </svg>
                        <FontAwesomeIcon onClick={onBtExport} icon={faFileExcel} fontSize='5x' style={{ fontSize: '1.7rem', cursor: 'pointer', position: "relative", marginLeft: '1rem', top: '0.3rem' }} className='' />
                    </div>
                </Col>
                <Col lg={12}>
                    <div className="ag-theme-quartz grid">
                        <AgGridReact
                            ref={usageGridRef}
                            onGridReady={onGridReady}
                            columnDefs={mode === 'summary' ? columnDefs.toSpliced(0, 1) : columnDefs}
                            defaultColDef={defaultColDef}
                            pagination={true}
                            rowBuffer={0}
                            rowSelection={rowSelection}
                            rowModelType={'infinite'}
                            cacheBlockSize={100}
                            cacheOverflowSize={2}
                            maxConcurrentDatasourceRequests={1}
                            infiniteInitialRowCount={100}
                            maxBlocksInCache={10}
                            enableCellTextSelection
                            tooltipShowDelay={0}
                            loading={loading}
                            
                        />
                    </div>
                </Col>
            </Row>

        </>

    )
}

export default UsageGrid;