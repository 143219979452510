import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
// Theme
import {  ModuleRegistry } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';


import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { Link } from "react-router-dom"
import { LoadingModal } from './AnalyticsCommon.js'
import { toast } from "react-toastify";
ModuleRegistry.registerModules([ClientSideRowModelModule]);


function Reports({ componentProps }) {
    const reportGridRef = useRef(null);
    const [reportData, setReportData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const toastProps = useMemo(() => {
        const tp = {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        }
        return tp;
    }, [])

    const loadReports =  useCallback(()=>{
        setShowModal(true);
        setLoading(true);
        fetch('/api/AnalyticsController/LoadReports', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: 'post',
            body: JSON.stringify({ 
				sfId: localStorage.getItem("salesforceId"),
				ProductRole: String(localStorage.getItem('ProductRole')),
                WholeSaleEndCustomerMode: String(localStorage.getItem('WholeSaleEndCustomerMode'))
				})
        }).then((resp) => resp.json()).then((data) => {            
            if (data.length > 0 && Object.hasOwn(data[0], "Id")) {
                
                setReportData(data);
                setLoading(false);
                //setTimeout(() => {
                setShowModal(false);
            }
            else {
                setLoading(false);
                //setTimeout(() => {
                setShowModal(false);
                setReportData([]);
                if (Object.hasOwn(data, "errorCode") && Object.hasOwn(data, "errorDescription"))
                    toast.error(data["errorDescription"], toastProps);
                else
                    toast.error("No report data Found", toastProps);
            }

            //},1000)
        }).catch((error) => {
            setShowModal(false);
            setLoading(false);
            setReportData([]);
            toast.error("Failed to Load Reports", toastProps);
            console.log(error)
        });
    }, [toastProps])
    useEffect( () => {
        loadReports();

    }, [loadReports])

    
    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
       
        flex: 1,
    };
    const [columnDefs, setcolumnDefs] = useState([
        {
            headerName: 'Report Name',
            headerTooltip: "Report Name",
            field: 'ReportName',
            tooltipField: "Report Name",
            minWidth: 200,
            cellRenderer: (params) => {               
                return <Link style={{ paddingLeft: '1rem' }} to={`/RunReport/${params["data"]["Id"]}`}>{params["data"]["ReportName"]}</Link>;
            }
        },
        {
            headerName: 'Description',
            headerTooltip: "Description",
            field: 'Description',            
            cellStyle: { marginLeft: '16px' },
            tooltipField: "Description",
            minWidth: 125
        },
        {
            headerName: 'Report Type',
            headerTooltip: 'Report Type',
            field: 'ReportType',
            tooltipField: "Report Type",
            cellStyle: { marginLeft: '16px' },
            minWidth: 250
        },       
    ])
    const paginationPageSize = 30;
    const paginationPageSizeSelector = [10, 30, 50, 100];
    const onGridReady = (params) => {
        window.addEventListener('resize', () => {
            setTimeout(() => {
                params.api.sizeColumnsToFit();
            }, 0);
        });
        params.api.sizeColumnsToFit();
    };
    return (
        <>
            <h2>Reports</h2>
            <LoadingModal showModal={showModal}></LoadingModal>
            <div className="ag-theme-quartz grid">
                <AgGridReact
                    ref={reportGridRef}

                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    rowData={reportData}
                    loadingOverlayComponent='customLoadingOverlay'

                    enableCellTextSelection
                    tooltipShowDelay={0}
                    pagination={true}
                    paginationPageSize={paginationPageSize}
                    paginationPageSizeSelector={paginationPageSizeSelector}
                    loading={loading}
                    
                />
            </div>
        </>
    );
}

export default Reports;
