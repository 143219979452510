import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';



function LoadingModal({ showModal }) {
    return (
        <>
            <Modal id="ChangeServicesLoader"
                show={showModal}
                backdrop={true}
                keyboard={false}
                centered
            >
                <Spinner animation="grow" role="status">
                </Spinner>
            </Modal>
        </>
    );
}

export { LoadingModal }