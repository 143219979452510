import React, { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';

const PhoneNumberReAssignment = ({ show, onHide, dataVoiceSIMs, onConfirm }) => {
    const [portOutExisting, setPortOutExisting] = useState(true);
    const [release, setRelease] = useState(false);
    const [portOutCompleted, setPortOutCompleted] = useState(false);
    const [option, setOption] = useState("PortOutExisting");

    const type = 'radio';

    const handleRadioCheck = (portOutExisting, release, portOutCompleted) => {
        setPortOutExisting(portOutExisting);
        setRelease(release);
        setPortOutCompleted(portOutCompleted);

        if (portOutExisting) setOption("PortOutExisting");
        if (release) setOption("Release");
        if (portOutCompleted) setOption("PortOutCompleted");
    };

    const handleConfirm = () => {
        onConfirm(option);
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} size="lg" backdrop="static" centered>
            <Modal.Header>
                <Modal.Title>Phone Number Re-Assignment</Modal.Title>
            </Modal.Header>
            <div style={{ maxHeight: '400px', overflowY: 'auto', padding: '10px' }}>
            <Modal.Body>
                <Row className="mb-4">
                        <h5>SIMs that include Voice:</h5>
                        <div style={{ whiteSpace: 'normal', wordWrap: 'break-word', marginBottom:'15px' }}>
                            {dataVoiceSIMs}
                        </div>
                        <span style={{ marginBottom:'10px' }}>
                                An additional step is required on SIMs that include data and voice. All requests to
                                de-activate are subject to term eligibility. Please select one of the options below to indicate your request:</span>
                   
                </Row>
                <Row className="ml-4">
                        <Form.Check
                            inline
                            label={
                                <div>
                                    Port-Out Phone Number(s)
                                    <br />
                                    <span style={{ marginLeft: '20px', color:'lightslategray' }} >
                                        Port-Out process to be facilitated by the destination provider</span>
                                </div>
                            }
                            name="group1"
                            type={type}
                            id="port-out"
                            onClick={() => handleRadioCheck(true, false, false)}
                            checked={portOutExisting}
                    />
                        <br></br>
                        <Form.Check
                            inline
                            label={
                                <div>
                                    De-Activate and Release Phone Number(s)
                                    <br />
                                    <span style={{ marginLeft: '20px', color: 'lightslategray' }}>
                                        Please note that once released you will lose access to the specified
                                        Phone Number(s) immediately
                                    </span>
                                </div>
                            }
                            name="group1"
                            type={type}
                            id="release"
                            onClick={() => handleRadioCheck(false, true, false)}
                            checked={release}
                    />
                    <br></br>
                        <Form.Check
                            inline
                            label={
                                <div>
                                    De-Activate only (Port-Out already completed)
                                </div>
                            }
                            name="group1"
                            type={type}
                            id="port-out-completed"
                            onClick={() => handleRadioCheck(false, false, true)}
                            checked={portOutCompleted}
                        />
                </Row>
                </Modal.Body>
            </div>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleConfirm}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PhoneNumberReAssignment;
