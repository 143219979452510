import React, { useEffect, useState, useContext } from 'react';
import CountUp from 'react-countup';
import moment from "moment";
import CardSummary from '../falcon/dashboard/CardSummary';
import ChartCard from "../common/ChartCard";
import Usage from "../common/Usage";
import { Col, Row, Spinner } from "reactstrap";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';
import { useDocumentTitle } from "../../helpers/useDocumentTitle";
import "./Home.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { generateErrorElementWithContactInfo, throwErrorIfNon200Response } from "../../helpers/utils";
import { UserContext } from "../context/UserContext"
export const Home = ({ usageData, usageDataLoading, usagePoolGroupData },props) => {
    useDocumentTitle("Advantix SmartSIM Portal - Home");

    const [usageMonthToDateData, setUsageMonthToDateData] = useState(null);
    const [usageByMonthData, setUsageByMonthData] = useState(null);
    const [usageMonthToDateLoading, setUsageMonthToDateLoading] = useState(false);
    const [usageByMonthLoading, setUsageByMonthLoading] = useState(false);
    const [nav1, setnav1] = useState('');
    const [nav2, setnav2] = useState('');
    const userContextData = useContext(UserContext)
    const settings = {
        dots: true,
        infinite: true,
        centerMode: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        initialSlide: 0,
    };
    const settings2 = {
        dots: true,
        infinite: true,
        centerMode: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        initialSlide: 0,
    };

    useEffect(() => {
        const controller = new AbortController(); // AbortController for cancelling the request
        const signal = controller.signal;

        console.log(userContextData);
        setUsageMonthToDateLoading(true);

        fetch('/api/SmartSIMUsageController/GetUsageMonthToDate', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: 'post',
            signal,
        })
            .then(throwErrorIfNon200Response)
            .then((response) => {
                setUsageMonthToDateLoading(false);
                return response.json();
            })
            .then((data) => {
                if (Array.isArray(data)) {
                    const formattedData = data.map((curr) => {
                        return {
                            ...curr,
                            usagedate: moment(curr.usagedate).format("DD")
                        };
                    });
                    setUsageMonthToDateData(formattedData);
                } else {
                    throw new Error("Error retrieving usage data.");
                }
            })
            .catch((ex) => {
                if (ex.name === "AbortError") {
                    console.log("Previous usageMonthToDate API call aborted.");
                } else {
                    console.log(`error: ${ex}`);
                    toast.error(generateErrorElementWithContactInfo("Error retrieving usage data."));
                }
                setUsageMonthToDateLoading(false);
            });

        return () => controller.abort();
    }, []); 

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        setUsageByMonthLoading(true);

        fetch('/api/SmartSIMUsageController/GetUsageByMonth', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('authToken')
            },
            method: 'post',
            signal,
        })
            .then(throwErrorIfNon200Response)
            .then((response) => {
                setUsageByMonthLoading(false);
                return response.json();
            })
            .then((data) => {
                if (Array.isArray(data)) {
                    const formattedData = data.map((curr) => {
                        return {
                            ...curr,
                            usagemonth: moment(curr.usagemonth).format("MMM")
                        };
                    });
                    setUsageByMonthData(formattedData);
                } else {
                    throw new Error("Error retrieving usage data.");
                }
            })
            .catch((ex) => {
                if (ex.name === "AbortError") {
                    console.log("Previous usageByMonth API call aborted.");
                } else {
                    console.log(`error: ${ex}`);
                    toast.error(generateErrorElementWithContactInfo("Error retrieving usage data."));
                }
                setUsageByMonthLoading(false);
            });

        return () => controller.abort(); 
    }, []);
    function setnav1value(x) {
        setnav1(x);
    };
    function setnav2value(y) {
        setnav2(y);
    };
    function slide1(item) {
        if (!usageDataLoading && usageData != null) {
            return (
                <div>
                    <CardSummary className="summary-card" rate={item ? item.UsagePercentage + " %" : ""} linkText="See Details" to="/usage"  title={item.PoolGroup == "Not Applicable" ? "USAGEs" : `Usage for ${item.PoolGroup}`} color="info">
                        {`${item?.UsageMonthToDate} / ${item?.DataPoolMB} GB`}
                    </CardSummary>
                </div>
            );
        }
    }
    function slide2(item2) {
        if (!usageDataLoading && usageData != null) {
            return (
                <div><Usage title={item2.PoolGroup == "Not Applicable" ? "Current Usage" : `Current Usage for ${item2.PoolGroup}`} loading={usageDataLoading}
                    usagePercentage={item2 ? item2.UsagePercentage : null}
                    projectedUsage={item2 ? item2.ProjectedUsage : null}
                    dataPoolMB={item2 ? item2.DataPoolMB : null}
                    usageMonthToDate={item2 ? item2.UsageMonthToDate : null}
                /></div>
            );
        }
    }
    
    return (
        <>
            <h4 className="text-secondary">Dashboard</h4>
            <Row>
                <Col className="mb-4" md={4}>
                    <CardSummary className="summary-card" rate="" title="DEVICES" color="warning" linkText="See all"
                        to="/inventory">
                        {!usageDataLoading ? usageData?.SIMCount : <Spinner type="grow" />}
                    </CardSummary>
                </Col>
                <Col className="mb-4" md={4}>

                    {!usageDataLoading ? usageData ? Array.isArray(usageData.PoolPlanGroup) && usageData.PoolPlanGroup.length > 0 ?
                        <div>
                            <Slider dots={true}
                                infinite={true}
                                centerMode={false}
                                slidesToShow={1}
                                slidesToScroll={1}
                                arrows={false}
                                initialSlide={0}
                                asNavFor={nav2}
                                ref={slider => (setnav1value(slider))}
                                slickGoTo={0, false}
                            >
                                {usageData.PoolPlanGroup.map(item => slide1(item))}
                            </Slider>
                        </div>
                        : null : 
                         null
                        : <CardSummary className="summary-card" rate="" title="USAGE" color="warning" linkText="See Details" to="/usage"> <Spinner type="grow" /></CardSummary>}

                </Col>
                <Col className="mb-4" md={4}>
                    <CardSummary className="summary-card" content={usageData ? parseInt(usageData.CurrentBill, 10) : ""}
                        rate={usageData ? `${usageData.BillingPercentDiff} %` : ""} title="LAST BILL"
                        color="success"
                        linkText="See Details" to="/billing">
                        {
                            !usageDataLoading ?
                                <CountUp end={usageData ? usageData?.CurrentBill : 0} duration={3} prefix="$"
                                    separator="," decimal="." />
                                : <Spinner type="grow" />
                        }
                    </CardSummary>
                </Col>
            </Row>
            <Row>
                <Col className="mb-4" md={4}>
                    <ChartCard
                        title="Daily Usage This Month"
                        data={usageMonthToDateData}
                        xDataKey="usagedate"
                        yDataKey="usagequantityMB"
                        labelUnit="MB"
                        loading={usageMonthToDateLoading}
                    />
                </Col>
                <Col className="mb-4" md={4}>
                    <div>
                        <Slider dots={true}
                            infinite={true}
                            centerMode={false}
                            slidesToShow={1}
                            slidesToScroll={1}
                            arrows={false}
                            initialSlide={1}
                            ref={slider => (setnav2value(slider))}
                            asNavFor={nav1}
                            //slickGoTo={0, false}
                        >
                            {!usageDataLoading ? usageData ? Array.isArray(usageData.PoolPlanGroup) && usageData.PoolPlanGroup.length > 0 ? usageData.PoolPlanGroup.sort((a,b)=>a-b).map(item => slide2(item))
                                : "" : "" : <Usage title="Current Usage" loading={usageDataLoading} />}
                        </Slider>
                    </div>
                </Col>
                <Col className="mb-4" md={4}>
                    <ChartCard
                        title="Usage By Month"
                        data={usageByMonthData}
                        xDataKey="usagemonth"
                        yDataKey="usagequantityMB"
                        labelUnit="MB"
                        loading={usageByMonthLoading}
                    />
                </Col>
            </Row>
        </>
    );
};
